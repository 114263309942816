const style = (theme) => {
  const output = {
    newsletter: {
      color: theme.colors[0],
      position: 'absolute',
      zIndex: theme.zindex.modal + 1,
      background: theme.colors[1],
      left: '18vw',
      right: '18vw',
      top: 70,
      bottom: 70,
      opacity: 0,
      pointerEvents: 'none',
      transition: 'opacity .5s ease-out',
      maxWidth: 770,
      margin: [0, 'auto'],
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: [0, 90],
      '& a, & button': {
        pointerEvents: 'none',
      },
    },
    visible: {
      pointerEvents: 'all',
      opacity: 1,
      '& a, & button': {
        pointerEvents: 'all',
      },
    },
    close: {
      position: 'absolute',
      zIndex: 1,
      top: 0,
      right: 0,
      width: 60,
      height: 60,
      padding: 20,
      cursor: 'pointer',
      '& svg': {
        width: 20,
        height: 20,
        fill: theme.colors[0],
      },
    },
    title: {
      extend: theme.typography.headings,
      color: theme.colors[2],
      textAlign: 'center',
      paddingTop: 50,
      marginBottom: 'var(--margin)',
    },
    privacy: {
      textAlign: 'left',
      extend: theme.typography.small,
      paddingBottom: 'calc(var(--margin) * 0.5)',
      marginTop: 20,
      opacity: 1,
      '& a': {
        extend: theme.link,
        color: theme.colors[0],
      },
    },
    hidden: {
      opacity: 0,
      pointerEvents: 'none',
      '& a': {
        pointerEvents: 'none',
      },
    },
    form: {
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
      borderBottom: `1px solid ${theme.colors[0]}`,
      margin: 0,
      padding: 0,
      '& .TextField__root': {
        flex: '1 1 auto',
        width: 'auto',
        padding: 0,
        margin: [0, 20, 0, 0],
        '& input': {
          background: 'none',
          border: 'none',
          borderRadius: 0,
          fontFamily: theme.fonts[0],
          color: theme.colors[0],
          padding: 0,
          margin: 0,
          '&::placeholder': {
            color: theme.colors[0],
          },
        },
      },
      '& .TextField__error': {
        fontFamily: theme.fonts[0],
        color: theme.colors[3],
        textTransform: 'uppercase',
        fontSize: 8,
        top: -7,
        left: 0,
      },
      '& .TextField__submit': {
        fontFamily: theme.fonts[0],
        color: theme.colors[0],
        cursor: 'pointer',
        '& span': {
          display: 'inline-block',
          marginRight: 10,
        },
        '& svg': {
          transform: 'translateY(1px)',
        },
      },
    },

    // Button Open
    button: {
      color: theme.colors[1],
      extend: [theme.typography.small, theme.link],
      position: 'absolute',
      bottom: 20,
      right: 20,
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    newsletter: {
      left: 20,
      right: 20,
      top: 80,
      bottom: 80,
      padding: [0, 30],
      justifyContent: 'flex-start',
      textAlign: 'center',
    },
    title: {
      marginTop: 0,
      paddingTop: 20,
    },
    form: {
      display: 'block',
      border: 'none',
      '& .TextField__root': {
        width: '100%',
        margin: [0, 0, 20, 0],
        '& input': {
          textAlign: 'center',
          borderBottom: `1px solid ${theme.colors[0]}`,
        },
      },
      '& .TextField__submit': {
        textAlign: 'center',
        margin: [0, 'auto'],
      },
    },
    privacy: {
      textAlign: 'center',
      marginTop: 40,
    },
  }

  return output
}

export default style
