import { lazy } from 'react'
import { internazionalizePath, getPathFromCpt } from '@/utils/path'

const Home = lazy(() => import(/* webpackChunkName: "home" */'@/pages/Home'))
const Generic = lazy(() => import(/* webpackChunkName: "generic" */'@/pages/Generic'))
const Contact = lazy(() => import(/* webpackChunkName: "contact" */'@/pages/Contact'))
const Biography = lazy(() => import(/* webpackChunkName: "biography" */'@/pages/Biography'))
const Event = lazy(() => import(/* webpackChunkName: "event" */'@/pages/Event'))
const Critic = lazy(() => import(/* webpackChunkName: "critic" */'@/pages/Critic'))
const Opera = lazy(() => import(/* webpackChunkName: "opera" */'@/pages/Opera'))
const ArchiveOpere = lazy(() => import(/* webpackChunkName: "archive_opere" */'@/pages/ArchiveOpere'))
const ArchiveEvents = lazy(() => import(/* webpackChunkName: "archive_events" */'@/pages/ArchiveEvents'))
const ArchiveCritica = lazy(() => import(/* webpackChunkName: "archive_critica" */'@/pages/ArchiveCritica'))
const NotFound = lazy(() => import(/* webpackChunkName: "not_found" */'@/pages/NotFound'))

export default (routing) => [
  {
    key: 0,
    path: internazionalizePath('/'),
    exact: true,
    render: () => <Home stub={routing.find((r) => r.cpt === 'home')?.slug} />,
  },
  {
    key: 1,
    path: internazionalizePath(
      getPathFromCpt(routing, 'page'),
    ),
    render: () => <Generic />,
  },
  {
    key: 2,
    path: internazionalizePath(
      getPathFromCpt(routing, 'biography'),
    ),
    render: () => <Biography />,
  },
  {
    key: 3,
    path: internazionalizePath(
      getPathFromCpt(routing, 'archive_opere'),
    ),
    exact: true,
    render: () => (
      <ArchiveOpere
        cpt={routing.find((r) => r.cpt === 'archive_opere').cpt}
      />
    ),
  },
  {
    key: 4,
    path: internazionalizePath(
      getPathFromCpt(routing, 'archive_events'),
    ),
    exact: true,
    render: () => (
      <ArchiveEvents
        cpt={routing.find((r) => r.cpt === 'archive_events').cpt}
      />
    ),
  },
  {
    key: 5,
    path: internazionalizePath(
      getPathFromCpt(routing, 'archive_critica'),
    ),
    exact: true,
    render: () => (
      <ArchiveCritica
        cpt={routing.find((r) => r.cpt === 'archive_critica').cpt}
      />
    ),
  },
  {
    key: 6,
    path: internazionalizePath(
      getPathFromCpt(routing, 'contact'),
    ),
    render: () => <Contact />,
  },
  {
    key: 7,
    path: internazionalizePath(
      getPathFromCpt(routing, '_cty_event'),
    ),
    render: () => (
      <Event
        cpt={routing.find((r) => r.cpt === '_cty_event').cpt}
      />
    ),
  },
  {
    key: 8,
    path: internazionalizePath(
      getPathFromCpt(routing, '_cty_critic'),
    ),
    render: () => (
      <Critic
        cpt={routing.find((r) => r.cpt === '_cty_critic').cpt}
      />
    ),
  },
  {
    key: 9,
    path: internazionalizePath(
      getPathFromCpt(routing, '_cty_opera'),
    ),
    render: () => (
      <Opera
        cpt={routing.find((r) => r.cpt === '_cty_opera').cpt}
      />
    ),
  },
  {
    key: 999,
    render: () => <NotFound />,
  },
]
