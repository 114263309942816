import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import injectSheet from 'react-jss'
import classNames from 'classnames'
import gsap from 'gsap'
import sprite from '@/assets/images/intro-sprite.png'
import * as loadingActions from '@/actions/loading'
import style from './style'

const Loader = ({ classes }) => {
  const $root = useRef()
  const $logo = useRef()
  const [spriteFinished, setSpriteFinished] = useState(false)

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { isSiteLoaded, isLoaderExited, strings } = useSelector((state) => ({
    isSiteLoaded: state.loading.isSiteLoaded,
    isLoaderExited: state.loading.isLoaderExited,
    strings: state.options.strings,
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const setLoaderExited = useCallback((bool) => dispatch(loadingActions.setLoadingValue('isLoaderExited', bool)), [dispatch])

  /*------------------------------
  On Complete Animation
  ------------------------------*/
  const onCompleteLoaderEnd = useCallback(() => setLoaderExited(true), [])

  /*------------------------------
  Loader End Animation
  ------------------------------*/
  const animateLoaderEnd = useCallback(() => {
    const timeline = gsap.timeline({ onComplete: onCompleteLoaderEnd })
    timeline
      .to($root.current, {
        autoAlpha: 0,
        delay: 0.5,
      })
  }, [])

  /*------------------------------
  Sprite Animation
  ------------------------------*/
  useEffect(() => {
    const spriteSheet = {
      width: 400,
      height: 400,
      total: 27,
      cols: 9,
      rows: 3,
      duration: 2,
    }
    const tl = gsap.timeline({
      onComplete: () => {
        setSpriteFinished(true)
      },
      delay: 0.2,
    })
    for (let i = 0; i < spriteSheet.total; i += 1) {
      tl.set($logo.current, {
        x: (i % spriteSheet.cols) * -spriteSheet.width,
        y: Math.floor(i / spriteSheet.cols) * -spriteSheet.height,
        z: 0,
      }, (i / (spriteSheet.total - 1)) * spriteSheet.duration)
    }

    if (process.env.NODE_ENV === 'development') {
      animateLoaderEnd()
    }
  }, [])

  /*------------------------------
  Animate Loader End
  ------------------------------*/
  useEffect(() => {
    if (isSiteLoaded && spriteFinished) animateLoaderEnd()
  }, [isSiteLoaded, spriteFinished])

  /*------------------------------
  Render
  ------------------------------*/
  return (
    <div
      className={classNames({
        [classes.root]: true,
        [classes.hide]: isLoaderExited,
      })}
      ref={$root}
    >
      <div className={classes.logoContainer}>
        <img ref={$logo} src={sprite} alt={strings.site_title} />
      </div>
    </div>
  )
}

export default injectSheet(style)(memo(Loader))
