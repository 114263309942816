import { useState } from 'react'
import { createContext } from 'use-context-selector'

const Context = createContext()
const { Provider, Consumer } = Context

const ContextComponent = ({
  children,
}) => {
  // const [isBackButtonVisible, setBackButtonVisible] = useState(false)
  // const [backButtonLabel, setBackButtonLabel] = useState('')
  const [backButtonLink, setBackButtonLink] = useState('')
  const [backButtonOnClick, setBackButtonOnClick] = useState('')
  const setBackButtonFunc = (fn) => setBackButtonOnClick(() => fn)
  const [newsScrollInit, setNewsScrollInit] = useState(0)
  const [isNewsExiting, setNewsExiting] = useState(false)
  const [lightboxImg, setLightboxImg] = useState('')
  const [lightboxVisible, setLightboxVisible] = useState(false)
  const [activeOpera, setActiveOpera] = useState(0)

  return (
    <Provider value={{
      // isBackButtonVisible,
      // setBackButtonVisible,
      // setBackButtonLabel,
      // backButtonLabel,
      setBackButtonLink,
      backButtonLink,
      setBackButtonFunc,
      backButtonOnClick,
      setNewsScrollInit,
      newsScrollInit,
      isNewsExiting,
      setNewsExiting,
      lightboxImg,
      setLightboxImg,
      lightboxVisible,
      setLightboxVisible,
      activeOpera,
      setActiveOpera,
    }}
    >
      {children}
    </Provider>
  )
}

export {
  Context,
  Consumer,
}

export default ContextComponent
