import { useRef, useMemo } from 'react'
import { useFrame } from '@react-three/fiber'
import { MathUtils, Color } from 'three'
import useStore from '@/zustand/store'

const CursorWebgl = ({ size = 10 }) => {
  const $cursor = useRef()
  const { mouse } = useStore()

  useFrame(({ viewport }) => {
    $cursor.current.position.x = MathUtils.lerp(
      $cursor.current.position.x,
      (mouse.x * viewport.width) / 2,
      0.05,
    )
    $cursor.current.position.y = MathUtils.lerp(
      $cursor.current.position.y,
      (mouse.y * viewport.height) / 2,
      0.05,
    )
  })

  const shaderArgs = useMemo(
    () => ({
      transparent: true,
      uniforms: {
        uColor: { value: new Color('#D30027') },
      },
      vertexShader: /* glsl */ `
      varying vec2 vUv;
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
      }
    `,
      fragmentShader: /* glsl */ `
      varying vec2 vUv;
      uniform vec3 uColor;
      void main() {
        float d = 1. - smoothstep(0., 0.5, length(vUv - 0.5));
        gl_FragColor = vec4( uColor, d );
      }
    `,
    }),
    [],
  )

  return (
    <mesh ref={$cursor}>
      <planeBufferGeometry args={[size, size]} />
      <shaderMaterial args={[shaderArgs]} />
    </mesh>
  )
}

export default CursorWebgl
