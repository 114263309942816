const style = (theme) => {
  const output = {
    cursorRoot: {},
    cursor: {
      '--size': 10,
      position: 'fixed',
      zIndex: theme.zindex.cursor,
      width: 'calc(var(--size) * 1px)',
      height: 'calc(var(--size) * 1px)',
      borderRadius: '50%',
      background: theme.colors[1],
      pointerEvents: 'none',
      margin: 'calc(var(--size) * -0.5px) 0 0 calc(var(--size) * -0.5px)',
    },
    cursor2: {
      '--size': 68,
      zIndex: theme.zindex.cursor + 1,
      background: 'none',
    },
    textWrap: {
      '--size': 72,
      zIndex: theme.zindex.cursor + 2,
      background: 'none',
    },
    textVisible: {
      '& $text': {
        transform: 'scale(1)',
        transition: 'transform .35s cubic-bezier(0.21, 0, 0.29, 1)',
      },
    },
    text: {
      position: 'absolute',
      borderRadius: '50%',
      width: '100%',
      height: '100%',
      border: `1px solid ${theme.colors[2]}`,
      boxShadow: `0 0 0 3px ${theme.colors[1]}`,
      textAlign: 'center',
      letterSpacing: '0.2em',
      fontSize: 12,
      background: theme.colors[1],
      textTransform: 'uppercase',
      color: theme.colors[0],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transformOrigin: '50% 50%',
      transition: 'transform .25s cubic-bezier(0.21, 0, 0.29, 1)',
      transform: 'scale(0)',
    },

    isLoading: {
      // border: `2px solid ${theme.getRgba(theme.colors[2], 0.01)}`,
      // transition: `all .1s ${theme.easings['power3.out']}`,
      // '& $spinnerLoader': {
      //   animation: '$rotate 2s linear infinite',
      //   opacity: 1,
      // },
      // '& $spinnerPath': {
      //   animation: '$dash 1.5s ease-in-out infinite',
      // },
    },
    spinnerLoader: {
      stroke: theme.getRgba(theme.colors[2], 1),
      width: '100%',
      height: '100%',
      transition: `all .2s ${theme.easings['power3.out']}`,
      animation: 'none',
      opacity: 1,
      position: 'absolute',
      top: 0,
      left: 0,
      transform: 'scale(1.28)',
    },
    spinnerPath: {
      strokeLinecap: 'round',
      strokeDasharray: '125 125',
      strokeDashoffset: '0',
    },
    spinnerThemeInverse: {
      '& $spinnerLoader': {
        stroke: theme.getRgba(theme.colors[3], 1),
      },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    cursor: {
      display: 'none',
    },
  }

  return output
}

export default style
