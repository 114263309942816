const style = (theme) => {
  // const burgerBorderHeight = 2
  const output = {
    root: {
      display: 'flex',
      position: 'relative',
      cursor: 'pointer',
    },
    text: {
      extend: theme.typography.small,
      color: theme.colors[1],
      textTransform: 'uppercase',
      width: 70,
      position: 'relative',
      zIndex: 1,
      overflow: 'hidden',
      height: 12,
      top: -2,
    },
    open: {
      position: 'absolute',
      right: 0,
      top: 0,
      transition: `transform 0.35s ${theme.easings['power3.out']}`,
    },
    close: {
      position: 'absolute',
      right: 0,
      top: 0,
      transform: 'translateY(100%)',
      transition: `transform 0.35s ${theme.easings['power3.out']}`,
    },
    box: {
      position: 'relative',
      display: 'inline-block',
      width: 25,
      height: 25,
      marginLeft: 20,
    },
    inner: {
      position: 'absolute',
      width: 25,
      height: 25,
      backgroundColor: 'trasparent',

      '& span': {
        display: 'inline-block',
        position: 'absolute',
        width: 25,
        height: 2,
        backgroundColor: theme.colors[1],
        right: 0,
        top: 0,
        transition: `all 0.35s ${theme.easings['power3.out']}`,
        '&:nth-child(2)': {
          top: 7,
          width: 15,
        },
      },
    },
    isActive: {
      '& $open': {
        transform: 'translateY(-100%)',
      },
      '& $close': {
        transform: 'translateY(0%)',
      },
      '& $inner': {
        '& span': {
          '&:nth-child(1)': {
            transform: 'rotate(45deg)',
            transformOrigin: '50 50%',
            width: 30,
            top: 3,
          },
          '&:nth-child(2)': {
            transform: 'rotate(-45deg)',
            transformOrigin: '50 50%',
            width: 30,
            top: 3,
          },
        },
      },
    },
    isHover: {
      '@media (hover: hover)': {
        '&:not($isActive)': {
          '& $inner': {
            '& span': {
              '&:nth-child(1)': {
                transformOrigin: '0 100%',
                width: 15,
              },
              '&:nth-child(2)': {
                transformOrigin: '0 100%',
                width: 25,
              },
            },
          },
        },
      },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {}

  return output
}

export default style
