import { AnimatePresence } from 'framer-motion'
import { Switch, useLocation } from 'react-router-dom'
import { useSelector, shallowEqual } from 'react-redux'

const AnimatedRoutes = ({
  children,
  exitBeforeEnter = true,
  initial = false,
}) => {
  const location = useLocation()
  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { isTransitionable } = useSelector((state) => ({
    isTransitionable: state.layout.router.transition,
  }), shallowEqual)

  return (
    <AnimatePresence
      exitBeforeEnter={exitBeforeEnter}
      initial={initial}
    >
      <Switch
        location={location}
        key={isTransitionable ? location.pathname : ''}
      >
        {children}
      </Switch>
    </AnimatePresence>
  )
}

export default AnimatedRoutes
