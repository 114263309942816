import { memo, useCallback, useEffect, useRef, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import { useRaf, useMouse } from 'rooks'
import classNames from 'classnames'
import gsap from 'gsap'
import Modernizr from '@/vendors/modernizr'
import NavItem from '@/components/NavItem'
import Newsletter from '@/components/Newsletter'
import usePrevious from '@/hooks/usePrevious'
import { decodeEntities } from '@/utils/decodeEntities'
import * as layerActions from '@/actions/layer'
import { cleanOrigin } from '@/utils/path'
import { lerp } from '@/utils/math'
import style from './style'

const useStyles = createUseStyles(style)

const Nav = () => {
  const $root = useRef()
  const $wrapper = useRef()
  const location = useLocation()
  const $img = useRef()
  const [isHover, setHover] = useState(false)
  const [active, setActive] = useState(0)

  /*------------------------------
  Mouse
  ------------------------------*/
  const { clientX: mouseX, clientY: mouseY } = useMouse()
  const lerpX = useRef(0)
  const prevMouseX = useRef(0)
  const speedMouseX = useRef(0)
  const lerpY = useRef(0)
  const prevMouseY = useRef(0)
  const speedMouseY = useRef(0)

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { pathname, isMenuOpen, mainMenu, headerHeight } = useSelector((state) => ({
    pathname: state.router.location.pathname,
    languages: state.locale.languages,
    isMenuOpen: state.layer.layers.some((layer) => layer.id === 'menu' && layer.isOpen),
    extraMenu: state.nav.extraMenu || [],
    productMenu: state.nav.productMenu || [],
    mainMenu: state.nav.mainMenu || [],
    footerMainMenu: state.nav.footerMainMenu || [],
    strings: state.options.strings,
    headerHeight: state.bounds?.header?.height || 0,
  }), shallowEqual)

  const prevPathname = usePrevious(pathname)
  const classes = useStyles({ headerHeight, isMenuOpen })

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const closeMenu = useCallback(() => dispatch(layerActions.closeMenu()), [dispatch])

  /*------------------------------
  Initialize
  ------------------------------*/
  const init = useCallback(() => {
    gsap.set($root.current, {
      autoAlpha: 0,
    })
    gsap.set($wrapper.current, { autoAlpha: 0 })
  }, [])

  /*------------------------------
  Active
  ------------------------------*/
  useEffect(() => {
    $img.current.querySelectorAll('img').forEach((img, index) => {
      gsap.killTweensOf(img)
      gsap.to(img, {
        opacity: index === active ? 1 : 0,
        duration: 0.01,
      })
    })
  }, [active])

  /*------------------------------
  Handle Esc Key Down
  ------------------------------*/
  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Escape' && e.keyCode === 27 && isMenuOpen) closeMenu()
  }, [isMenuOpen])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [isMenuOpen])

  /*------------------------------
  Open Main Menu
  ------------------------------*/
  const openMainMenu = useCallback(() => {
    if (Modernizr.devicehastouch) $root.current.scrollTop = 0
    gsap.killTweensOf([$root.current, $wrapper.current])
    const tl = gsap.timeline({ ease: 'power3.out' })
    tl
      .to($root.current, {
        duration: 0.6,
        autoAlpha: 1,
      })
      .to($wrapper.current, {
        duration: 0.5,
        autoAlpha: 1,
      }, '<0.2')
  }, [])

  /*------------------------------
  Close Main Menu
  ------------------------------*/
  const closeMainMenu = useCallback(() => {
    gsap.killTweensOf([$root.current, $wrapper.current])
    gsap.to($root.current, {
      duration: 1,
      autoAlpha: 0,
    })
    gsap.to($wrapper.current, {
      duration: 0.5,
      autoAlpha: 0,
    })
  }, [])

  /*------------------------------
  Init
  ------------------------------*/
  useEffect(() => {
    init()
  }, [])

  /*------------------------------
  Close Nav when isMenuOpen changed
  ------------------------------*/
  const prevIsOpen = usePrevious(isMenuOpen)
  useEffect(() => {
    if (prevIsOpen !== undefined && isMenuOpen) openMainMenu()
    if (prevIsOpen !== undefined && !isMenuOpen) closeMainMenu()
  }, [isMenuOpen])

  /*------------------------------
  Close Nav on change page
  ------------------------------*/
  useLayoutEffect(() => {
    if (prevPathname !== pathname) {
      setTimeout(() => {
        if (isMenuOpen) closeMenu()
      }, 500)
    }
  }, [pathname])

  /*------------------------------
  Render Main Nav
  ------------------------------*/
  const renderMainNav = useCallback(() => {
    return (
      <nav className={`${classes.nav} ${classes.mainNav}`}>
        <ul
          onMouseEnter={() => {
            setHover(true)
          }}
          onMouseLeave={() => {
            setHover(false)
          }}
        >
          {mainMenu.map((item, index) => {
            return (
              <li
                className={classNames({
                  [item.classes && item.classes.join(' ')]: true,
                  [classes.navItem]: true,
                  [classes.navItemDisabled]: cleanOrigin(item.url) === pathname,
                })}
                key={item.ID}
                onMouseEnter={() => setActive(index)}
              >
                <NavItem
                  visible={isMenuOpen}
                  url={item.url}
                  value={decodeEntities(item.title)}
                />
              </li>
            )
          })}
        </ul>
      </nav>
    )
  }, [mainMenu, location, isMenuOpen, pathname])

  /*------------------------------
  Render Images
  ------------------------------*/
  const renderImages = useCallback(() => {
    return mainMenu.length > 0 && mainMenu.map((item, index) => (
      <img key={index.toString()} src={item.attr} alt={item.title} />
    ))
  }, [mainMenu])

  /*------------------------------
  RAF
  ------------------------------*/
  useRaf(() => {
    speedMouseX.current = lerp(speedMouseX.current, prevMouseX.current - mouseX, 0.1)
    speedMouseY.current = lerp(speedMouseY.current, prevMouseY.current - mouseY, 0.1)

    const rotate = Math.abs(speedMouseX.current) > Math.abs(speedMouseY.current) ? speedMouseX.current : speedMouseY.current
    lerpX.current = lerp(lerpX.current, mouseX, 0.1)
    lerpY.current = lerp(lerpY.current, mouseY, 0.1)
    prevMouseX.current = mouseX
    prevMouseY.current = mouseY

    $img.current.style.left = `${lerpX.current}px`
    $img.current.style.top = `${lerpY.current}px`
    $img.current.style.transform = `translate(-50%, -50%) rotate(${rotate * 0.8}deg)`
  }, isMenuOpen && !Modernizr.devicehastouch)

  return (
    <>
      <div
        className={classes.root}
        ref={$root}
        role="dialog"
        aria-modal="true"
        aria-label="nav"
      >
        <div className={classes.container}>
          <div
            className={classes.wrapper}
            ref={$wrapper}
          >
            {renderMainNav()}
            <div
              className={classNames({
                [classes.followImage]: true,
                [classes.followImageVisible]: isHover && !Modernizr.devicehastouch,
              })}
              ref={$img}
            >
              {renderImages()}
            </div>
          </div>
        </div>
        <Newsletter />
      </div>
    </>
  )
}

export default memo(Nav)
