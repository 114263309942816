import { memo, useEffect, useRef, useLayoutEffect, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { createUseStyles } from 'react-jss'
import gsap from 'gsap'
import { useWindowSize } from 'rooks'
import usePrevious from '@/hooks/usePrevious'
import YoutubeVideo from '@/components/YoutubeVideo'
import * as layerActions from '@/actions/layer'
import * as cursorActions from '@/actions/cursor'
import Modernizr from '@/vendors/modernizr'
import style from './style'

const useStyles = createUseStyles(style)

const ModalVideo = () => {
  const $root = useRef()
  const classes = useStyles()

  const { innerWidth, innerHeight } = useWindowSize()

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { isModalOpen, video, pathname } = useSelector((state) => ({
    pathname: state.router.location.pathname,
    isModalOpen: state.layer.layers.some((layer) => layer.id === 'modalVideo' && layer.isOpen),
    video: state.layer.layers.filter((v) => v.id === 'modalVideo')[0]?.video || '',
  }), shallowEqual)
  const prevPathname = usePrevious(pathname)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const closeLayer = useCallback(() => dispatch(layerActions.closeLayer({ id: 'modalVideo' })), [dispatch])
  const setCursorHover = useCallback((bool) => dispatch(cursorActions.setCursorValue('hover', bool)), [dispatch])
  const setCursorText = useCallback((value) => dispatch(cursorActions.setCursorValue('text', value)), [dispatch])

  /*------------------------------
  Initialize
  ------------------------------*/
  const init = useCallback(() => {
    gsap.set($root.current, {
      autoAlpha: 0,
    })
  }, [])

  /*------------------------------
  Manage Esc Key Down
  ------------------------------*/
  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Escape' && e.keyCode === 27 && isModalOpen) closeLayer()
  }, [isModalOpen])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [isModalOpen])

  /*------------------------------
  Init
  ------------------------------*/
  useEffect(() => {
    init()
  }, [])

  /*------------------------------
  Animations
  ------------------------------*/
  const openModal = useCallback(() => {
    gsap.killTweensOf($root.current)
    gsap.to($root.current, {
      duration: 1.4,
      autoAlpha: 1,
      ease: 'power3.out',
      delay: 0.1,
    })
  }, [])

  const closeModal = useCallback(() => {
    gsap.killTweensOf($root.current)
    gsap.to($root.current, {
      duration: 1.4,
      autoAlpha: 0,
      ease: 'power3.out',
    })
  }, [])

  /*------------------------------
  Close Nav on change page
  ------------------------------*/
  useLayoutEffect(() => {
    if (
      prevPathname !== undefined
      && prevPathname !== pathname
    ) {
      setTimeout(() => {
        closeModal()
      }, 500)
    }
  }, [pathname])

  /*------------------------------
  Close Nav when isMenuOpen changed
  ------------------------------*/
  const prevIsModalOpen = usePrevious(isModalOpen)
  useEffect(() => {
    if (prevIsModalOpen !== undefined && isModalOpen) openModal()
    if (prevIsModalOpen !== undefined && !isModalOpen) closeModal()
  }, [isModalOpen])

  /*------------------------------
  Handle Close
  ------------------------------*/
  const handleClose = useCallback(() => closeLayer(), [])

  /*------------------------------
  Render Video
  ------------------------------*/
  const renderVideo = useCallback(() => {
    const aspect = (innerWidth / innerHeight) > 16 / 9
    const width = !aspect ? '100%' : `${(innerHeight * 16) / 9}px`
    const height = aspect ? '100%' : `${(innerWidth * 9) / 16}px`
    return isModalOpen && (
      <div className={classes.wrapper} style={{ width, height }}>
        {Modernizr.devicehastouch
        && <iframe className={classes.iframe} width="560" height="315" src={`https://www.youtube.com/embed/${video.id}?autoplay=1`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />}
        {!Modernizr.devicehastouch
        && (
        <YoutubeVideo
          videoId={video.id}
          autoplay={isModalOpen}
          poster={video.img}
        />
        )}
      </div>
    )
  }, [isModalOpen, innerWidth, innerHeight])

  return (
    <div
      className={classes.root}
      ref={$root}
      role="dialog"
      aria-modal="true"
    >
      <button
        className={classes.close}
        onClick={handleClose}
        onMouseEnter={() => {
          setCursorText('cursor_exit')
          setCursorHover(true)
        }}
        onMouseLeave={() => {
          setCursorText('')
          setCursorHover(false)
        }}
        aria-label="close-modal"
      >
        <svg><use xlinkHref="#ico-close" /></svg>
      </button>
      {renderVideo()}
    </div>
  )
}

export default memo(ModalVideo)
