const style = (theme) => {
  const output = {
    root: {
      position: 'relative',
      width: '100%',
      height: 0,
      paddingBottom: '56.25%',
      '& iframe': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 0,
        pointerEvents: 'none',
      },
    },
    poster: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      zIndex: 2,
      transition: 'all 0.3s ease-in',
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: theme.getRgba(theme.colors[0], 0.6),
      },
    },
    posterHide: {
      opacity: 0,
      pointerEvents: 'none',
    },
    timeline: {
      position: 'absolute',
      zIndex: 1,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      padding: '40px 20px 20px 20px',
      background: 'linear-gradient(to top, rgba(255, 0, 0, .3)0, rgba(255, 0, 0, 0) 20%)',
    },
    play: {
      position: 'absolute',
      top: 'calc(50% - 40px)',
      left: 'calc(50% - 40px)',
      borderRadius: '50%',
      width: 80,
      height: 80,
      cursor: 'pointer',
      background: theme.colors[1],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& svg': {
        fill: theme.colors[0],
        transition: 'transform .3s ease-out',
        width: '20px',
        height: '20px',
        marginLeft: '3px',
        transform: 'scaleX(0.8)',
      },
      '&:hover': {
        '& svg': {
          transform: 'scale(1, 1.2)',
        },
      },
    },
    playHide: {
      opacity: 0,
      pointerEvents: 'none',
    },
    '@keyframes rotate': {
      from: { transform: 'translate(-50%, -50%) rotate(0)' },
      to: { transform: 'translate(-50%, -50%) rotate(360deg)' },
    },
    '@keyframes dash': {
      '0%': {
        strokeDasharray: '1, 150',
        strokeDashoffset: 0,
      },
      '50%': {
        strokeDasharray: '90, 150',
        strokeDashoffset: -35,
      },
      '100%': {
        strokeDasharray: '90, 150',
        strokeDashoffset: -124,
      },
    },
    loading: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '60px',
      height: '60px',
      transformOrigin: 'center',
      animation: '$rotate 1s linear infinite',
      transition: 'all 0.3s ease-in',
      opacity: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      pointerEvents: 'none',
      '& svg': {
        stroke: 'white',
        strokeWidth: 1,
        fill: 'none',
        width: 60,
        height: 60,
        animation: '$dash 1.5s ease-in-out infinite',
      },
    },
    loadingShow: {
      opacity: 1,
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {}
  return output
}

export default style
