import {
  SET_LAYOUT_STYLE,
  SET_LAYOUT_BACK_PROP,
  RESET_LAYOUT_BACK_PROP,
  SET_LAYOUT_HEADER_MINIMIZE,
  SET_LAYOUT_HEADER_LOCKED,
  SET_LAYOUT_TRANSITION,
} from '@/actions/types'

const initialState = {
  style: 'default',
  backButton: {
    label: 'Back',
    visible: false,
    link: '',
    callback: false,
  },
  header: {
    minimize: false,
    locked: false,
  },
  router: {
    transition: true,
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LAYOUT_STYLE:
      state = {
        ...state,
        style: action.payload,
      }
      break
    case SET_LAYOUT_BACK_PROP:
      state = {
        ...state,
        backButton: {
          ...state.backButton,
          [action.key]: action.payload,
        },
      }
      break
    case RESET_LAYOUT_BACK_PROP:
      state = {
        ...state,
        backButton: initialState.backButton,
      }
      break
    case SET_LAYOUT_HEADER_MINIMIZE:
      state = {
        ...state,
        header: {
          ...state.header,
          minimize: action.payload,
        },
      }
      break
    case SET_LAYOUT_HEADER_LOCKED:
      state = {
        ...state,
        header: {
          ...state.header,
          locked: action.payload,
        },
      }
      break
    case SET_LAYOUT_TRANSITION:
      state = {
        ...state,
        router: {
          transition: action.payload,
        },
      }
      break
    default:
      return { ...state }
  }
  return { ...state }
}
