const style = (theme) => {
  const output = {
    root: {
      position: 'fixed',
      top: 0,
      width: '100%',
      height: 'var(--vh)',
      zIndex: theme.zindex.header,
      background: 'transparent',
      pointerEvents: 'none',
      userSelect: 'none',
      '& a, & button': {
        pointerEvents: 'all',
      },
    },
    imageIsZoomed: {
      '& a, & button': {
        pointerEvents: 'none',
      },
    },
    logo: {
      position: 'absolute',
      zIndex: 1,
      top: 20,
      left: 20,
    },
    socialMenu: {
      position: 'absolute',
      hidden: 1,
      top: '50%',
      left: 24,
      display: 'flex',
      transform: 'rotate(-90deg) translate(-50%, -50%)',
      transformOrigin: '0% 0%',
      transition: 'opacity 0.4s ease-out',
      '& li': {
        margin: [0, 25],
      },
      '& a': {
        color: 'inherit',
        extend: [theme.typography.small, theme.link],
      },
    },
    hidden: {
      opacity: 0,
      pointerEvents: 'none',
    },
    lang: {
      position: 'absolute',
      zIndex: 1,
      bottom: 20,
      left: 20,
      extend: theme.typography.small,
      transition: 'opacity 0.4s ease-out',
      pointerEvents: 'none',
      '& *': {
        pointerEvents: 'none!important',
      },
    },
    burgerButton: {
      position: 'absolute',
      zIndex: 1,
      top: 13,
      right: 10,
      transition: 'opacity 0.4s ease-out',
      padding: [20, 20, 0, 0],
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    logo: {
      top: 17,
      left: 20,
    },
    burgerButton: {
      top: 10,
      right: 5,
    },
    socialMenu: {
      transform: 'rotate(0)',
      top: 'auto',
      left: 48,
      bottom: 20,
      lineHeight: 1,
      '& li': {
        margin: [0, 21],
      },

    },
  }

  return output
}

export default style
