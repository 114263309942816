/* eslint-disable no-nested-ternary */
import { useRef, useEffect } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import { useMouse, useRaf, useWindowSize } from 'rooks'
import { MathUtils } from 'three'
import gsap from 'gsap'
import Modernizr from '@/vendors/modernizr'
import useStore from '@/zustand/store'
import style from './style'

const useStyles = createUseStyles(style)

const CursorFollow = () => {
  const classes = useStyles()
  const { setMouse } = useStore()

  const { innerWidth, innerHeight } = useWindowSize()

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { strings, cursorText, cursorHover, isLoading } = useSelector((state) => ({
    strings: state.options.strings,
    cursorText: state.cursor.text,
    cursorHover: state.cursor.hover,
    isLoading: state.loading.isLoading,
  }), shallowEqual)

  const $cursor1 = useRef()
  const $cursor2 = useRef()
  const $spinner = useRef()
  const $text = useRef()
  const scale = useRef({
    cursor1: 1,
    cursor2: 1,
  })

  const { clientX, clientY } = useMouse()
  const mouseCursor1 = useRef({
    x: window.innerWidth / 2,
    y: window.innerHeight / 2,
  })
  const mouseCursor2 = useRef({
    x: window.innerWidth / 2,
    y: window.innerHeight / 2,
  })

  useEffect(() => {
    gsap.to(scale.current, {
      cursor1: cursorHover ? 0 : 1,
      cursor2: cursorHover ? cursorText === '' ? 0.5 : 1 : 1,
      duration: 0.2,
    })
  }, [cursorHover])

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        gsap.killTweensOf($spinner.current)
        gsap.to($spinner.current, {
          duration: 0.6,
          strokeDashoffset: -250,
          ease: 'power3.out',
          repeat: 0,
        })
      }, 200)
    } else {
      gsap.killTweensOf($spinner.current)
      gsap.fromTo($spinner.current, {
        strokeDashoffset: 0,
      }, {
        strokeDashoffset: -250,
        repeat: -1,
        duration: 2,
        ease: 'none',
      })
    }
  }, [isLoading])

  useRaf(() => {
    setMouse({ x: (clientX / innerWidth) * 2 - 1, y: (1 - (clientY / innerHeight)) * 2 - 1 })

    mouseCursor1.current.x = MathUtils.lerp(
      mouseCursor1.current.x,
      clientX,
      0.11,
    )
    mouseCursor1.current.y = MathUtils.lerp(
      mouseCursor1.current.y,
      clientY,
      0.11,
    )

    $cursor1.current.style.transform = `translate(${mouseCursor1.current.x}px, ${mouseCursor1.current.y}px) scale(${scale.current.cursor1})`
    $text.current.style.transform = `translate(${mouseCursor1.current.x}px, ${mouseCursor1.current.y}px)`

    mouseCursor2.current.x = MathUtils.lerp(
      mouseCursor2.current.x,
      clientX,
      0.1,
    )
    mouseCursor2.current.y = MathUtils.lerp(
      mouseCursor2.current.y,
      clientY,
      0.1,
    )
    $cursor2.current.style.transform = `translate(${mouseCursor2.current.x}px, ${mouseCursor2.current.y}px) scale(${scale.current.cursor2}`
  }, !Modernizr.devicehastouch)

  return !Modernizr.devicehastouch && (
    <div className={classes.cursorRoot}>
      <div
        className={classNames({
          [classes.cursor]: true,
          [classes.cursor1]: true,
        })}
        ref={$cursor1}
      />
      <div
        className={classNames({
          [classes.cursor]: true,
          [classes.cursor2]: true,
        })}
        ref={$cursor2}
      >
        <svg
          viewBox="0 0 50 50"
          className={classNames({
            [classes.spinnerLoader]: true,
            [classes.isLoading]: isLoading,
          })}
        >
          <circle
            ref={$spinner}
            className={classes.spinnerPath}
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="1"
          />
        </svg>
      </div>
      <div
        className={classNames({
          [classes.cursor]: true,
          [classes.textWrap]: true,
          [classes.textVisible]: cursorText !== '',
        })}
        ref={$text}
      >
        <div className={classNames({
          [classes.text]: true,
        })}
        >
          {strings[cursorText]}
        </div>
      </div>
    </div>
  )
}

export default CursorFollow
