const padding = '15px'

const style = (theme) => {
  const output = {
    root: {
      display: 'flex',
      color: 'white',
      alignItems: 'flex-end',
      height: '100%',
    },
    pause: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      '& svg': {
        position: 'absolute',
        bottom: 15,
        left: 15,
        cursor: 'pointer',
        fontSize: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 36,
        height: 36,
        fill: theme.colors[1],
      },
    },
    fullscreen: {
      cursor: 'pointer',
      position: 'absolute',
      bottom: 15,
      right: 20,
      width: 36,
      height: 36,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& svg': {
        fill: theme.colors[1],
        width: 36,
        height: 36,
      },
    },
    progressWrapper: {
      position: 'relative',
      flex: 1,
      display: 'block',
      height: '1px',
      padding: `${padding} 0`,
      cursor: 'pointer',
      marginLeft: 30,
      marginRight: 30,
      '&:before': {
        content: '""',
        background: 'rgba(255, 255, 255, .3)',
        position: 'absolute',
        top: padding,
        left: 0,
        width: '100%',
        height: '1px',
      },
    },
    progress: {
      position: 'absolute',
      top: padding,
      left: 0,
      width: '100%',
      height: '1px',
      background: theme.colors[1],
      transformOrigin: 'left',
    },
  }
  return output
}

export default style
