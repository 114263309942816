const style = (theme) => {
  const output = {
    root: {
      position: 'relative',
      zIndex: 1,
      color: 'currentColor',
      width: 20,
      '&.open': {
        '& $select': {
          '& $arrow': {
            transform: 'rotate(180deg)',
          },
        },
        '& $options': {
          opacity: 1,
          pointerEvents: 'all',
          transform: 'translateY(0%)',
        },
      },
    },
    select: {
      borderRadius: 20,
      extend: theme.typography.small,
      padding: [10, 0, 0],
      color: 'currentColor',
      cursor: 'pointer',
      margin: 0,
      textTransform: 'uppercase',
      display: 'flex',
      alignItems: 'center',
    },
    arrow: {
      position: 'relative',
      bottom: 0,
      marginLeft: 8,
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '5px 5px 0',
      borderColor: `${theme.colors[0]} transparent transparent transparent`,
      backfaceVisibility: 'hidden',
      willChange: 'transform',
      transition: `all 0.2s ${theme.easings.garret}`,
    },
    options: {
      position: 'absolute',
      zIndex: 1,
      bottom: '100%',
      left: '-10%',
      width: '120%',
      overflow: 'hidden',
      opacity: 0,
      transform: 'translateY(-2%)',
      transition: 'opacity .2s ease-out, transform .2s ease-out',
      pointerEvents: 'none',
      extend: theme.typography.small,
    },
    option: {
      display: 'block',
      width: '100%',
      padding: [10, 0, 10, 3],
      color: 'inherit',
      textTransform: 'uppercase',
      textDecoration: 'none',
      lineHeight: 1,
      borderBottom: `1px solid ${theme.getRgba(theme.colors[1], 0.4)}`,
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {

  }

  return output
}

export default style
