import {
  SET_LAYOUT_STYLE,
  SET_LAYOUT_BACK_PROP,
  RESET_LAYOUT_BACK_PROP,
  SET_LAYOUT_HEADER_MINIMIZE,
  SET_LAYOUT_HEADER_LOCKED,
  SET_LAYOUT_TRANSITION,
} from '@/actions/types'

const setLayoutStyle = (value) => ({
  type: SET_LAYOUT_STYLE,
  payload: value,
})

const setLayoutHeaderMinimize = (bool) => ({
  type: SET_LAYOUT_HEADER_MINIMIZE,
  payload: bool,
})

const setLayoutHeaderLocked = (bool) => ({
  type: SET_LAYOUT_HEADER_LOCKED,
  payload: bool,
})

const setLayoutTransition = (bool) => ({
  type: SET_LAYOUT_TRANSITION,
  payload: bool,
})

const setBackButtonProp = (key, value) => ({
  type: SET_LAYOUT_BACK_PROP,
  payload: value,
  key,
})

const resetBackButtonProp = () => ({ type: RESET_LAYOUT_BACK_PROP })

export {
  setLayoutStyle,
  setBackButtonProp,
  resetBackButtonProp,
  setLayoutHeaderMinimize,
  setLayoutHeaderLocked,
  setLayoutTransition,
}
