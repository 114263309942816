export const getBoundingClientRect = (element, extra) => {
  if (!element) return false
  const bounding = {}
  const boundingRect = element.getBoundingClientRect();
  ['top', 'right', 'bottom', 'left', 'width', 'height'].forEach(
    (k) => (bounding[k] = boundingRect[k]),
  )

  if (extra) {
    const style = window.getComputedStyle(element)
    const styleKeys = [
      'margin-bottom',
      'margin-left',
      'margin-right',
      'margin-top',
      'padding-bottom',
      'padding-left',
      'padding-right',
      'padding-top',
    ]
    styleKeys.forEach((k) => {
      const edge = k.split('-')[1]
      const number = style[k].includes('px')
        ? Number(style[k].match(/\d/g).join(''))
        : 0
      bounding[edge] += number
      const size = ['left', 'right'].includes(edge) ? 'width' : 'height'
      bounding[size] += number
    })
  }
  return bounding
}

export const isInViewport = (el) => {
  const { top, height, left, width } = el.getBoundingClientRect()
  return (top + height > 0 && top <= window.innerHeight && left + width > 0 && left <= window.innerWidth)
}

export const externalPreload = (callback) => {
  if (document.documentElement.classList.contains('ready')) {
    callback(true)
  } else {
    document.documentElement.classList.add('ready')
    setTimeout(() => {
      callback(true)
    }, 500)
  }
}

export const wrapLine = (el, wrapper) => {
  el.parentNode.insertBefore(wrapper, el)
  wrapper.classList.add('line')
  wrapper.appendChild(el)
}

export const debounce = (func, wait, immediate) => {
  let timeout

  return function executedFunction() {
    const context = this
    const args = arguments // eslint-disable-line

    const later = () => {
      timeout = null
      if (!immediate) func.apply(context, args)
    }

    const callNow = immediate && !timeout

    clearTimeout(timeout)

    timeout = setTimeout(later, wait)

    if (callNow) func.apply(context, args)
  }
}
