import {
  RECEIVE_FILTERED_PRODUCT_LIST,
  RECEIVE_FILTERS,
  FREEZE_FILTERS,
} from '@/actions/types'

const initialState = {
  products: [],
  filteredProducts: [],
  filters: [],
  filterIsLoading: false,
  totalPosts: 0,
  totalPages: 1,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_FILTERED_PRODUCT_LIST:
      state = {
        ...state,
        products: action.payload,
      }
      break
    case RECEIVE_FILTERS:
      state = {
        ...state,
        filters: action.payload,
      }
      break
    case FREEZE_FILTERS:
      state = {
        ...state,
        filterIsLoading: action.payload,
      }
      break
    default:
      return { ...state }
  }
  return { ...state }
}
