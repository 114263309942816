import { memo, useRef, useState, useCallback, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import HTMLParse from 'react-html-parser'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import classNames from 'classnames'
import WpContactForm7 from '@/components/WpContactForm7'
import RevealFadeIn from '@/components/RevealFadeIn'
import RevealChars from '@/components/RevealChars'
import * as cursorActions from '@/actions/cursor'
import * as formActions from '@/actions/form'
import style from './style'

const useStyles = createUseStyles(style)

const Newsletter = () => {
  const classes = useStyles()
  const $newsletter = useRef()
  const formId = useRef()
  const [isNewsletterOpen, setNewsletterOpen] = useState(false)
  const [callback, setCallback] = useState('')

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const setCursorHover = useCallback((bool) => dispatch(cursorActions.setCursorValue('hover', bool)), [dispatch])
  const setCursorText = useCallback((value) => dispatch(cursorActions.setCursorValue('text', value)), [dispatch])
  const fetchForm = useCallback((id) => dispatch(formActions.fetchForm(id)), [dispatch])
  const sendContactForm = useCallback((id, data, isMultipart) => dispatch(formActions.sendContactForm(id, data, isMultipart)), [dispatch])

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { strings, currentForm, isMenuOpen } = useSelector((state) => ({
    isMenuOpen: state.layer.layers.some((layer) => layer.id === 'menu' && layer.isOpen),
    strings: state.options.strings,
    currentForm: state.form[formId.current] || {},
  }), shallowEqual)

  /*------------------------------
  Fetch Form
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(strings).length > 0) {
      formId.current = strings.newsletter_formID
      fetchForm(formId.current)
    }
  }, [strings])

  useEffect(() => {
    if (!isMenuOpen) {
      setNewsletterOpen(false)
    }
  }, [isMenuOpen])

  return Object.values(currentForm).length > 0 && (
    <>
      <div
        ref={$newsletter}
        className={classNames({
          [classes.newsletter]: true,
          [classes.visible]: isNewsletterOpen && isMenuOpen,
        })}
      >
        <RevealChars inview={isNewsletterOpen} className={classes.title}>
          {strings.title_newsletter}
        </RevealChars>
        <RevealFadeIn inview={isNewsletterOpen} enterDelay={1}>
          <WpContactForm7
            className={classes.form}
            fields={currentForm.fields}
            formID={formId.current}
            sendContactForm={sendContactForm}
            submitLabel={strings.label_submit_newsletter}
            handleCallback={(e) => {
              setCallback(e)
            }}
          />
          <div className={classNames({
            [classes.privacy]: true,
            [classes.hidden]: callback !== '',
          })}
          >
            {HTMLParse(strings.privacy_newsletter)}
          </div>
        </RevealFadeIn>

        <button
          className={classes.close}
          onClick={() => { setNewsletterOpen(false) }}
          onMouseEnter={() => {
            setCursorHover(true)
            setCursorText('')
          }}
          onMouseLeave={() => {
            setCursorHover(false)
          }}
        >
          <svg>
            <use xlinkHref="#ico-close" />
          </svg>
        </button>
      </div>
      <button
        className={classes.button}
        onClick={() => { setNewsletterOpen(true) }}
        onMouseEnter={() => {
          setCursorHover(true)
          setCursorText('')
        }}
        onMouseLeave={() => {
          setCursorHover(false)
        }}
      >
        {strings.label_newsletter}
      </button>
    </>
  )
}

Newsletter.defaultProps = {
}

export default memo(Newsletter)
