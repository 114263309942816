import { useEffect, useRef, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import omit from 'omit.js'
import { history } from '@/base/store'
import { cleanOrigin, isInternal } from '@/utils/path'
import * as cursorActions from '@/actions/cursor'
import * as layoutActions from '@/actions/layout'
import constants from '@/constants'

const DelayLink = (props) => {
  const timeout = useRef()
  const url = useRef([])

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { isTransitionable } = useSelector((state) => ({
    isTransitionable: state.layout.router.transition,
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const setLayoutTransition = useCallback((bool) => dispatch(layoutActions.setLayoutTransition(bool)), [dispatch])
  const setCursorHover = useCallback((bool) => dispatch(cursorActions.setCursorValue('hover', bool)), [dispatch])
  const setCursorText = useCallback((value) => dispatch(cursorActions.setCursorValue('text', value)), [dispatch])

  useEffect(() => {
    url.current = cleanOrigin(props.to).split('.')
    return () => {
      if (timeout.current) clearTimeout(timeout.current)
    }
  }, [])

  const handleClick = (e) => {
    if (!isTransitionable) setLayoutTransition(true)
    if (e.defaultPrevented) return
    const notCmdClick = (e.metaKey === undefined && e.ctrlKey === undefined) || (!e.metaKey && !e.ctrlKey)
    if (!notCmdClick) return
    e.preventDefault()
    const link = cleanOrigin(props.to)

    if (history.location.pathname !== link) {
      props.onDelayStart(e, link)

      if (props.doAnimation !== undefined) props.doAnimation()

      timeout.current = setTimeout(() => {
        if (props.replace) {
          history.replace(link)
        } else {
          history.push(link)
        }
        props.onDelayEnd(e, link)
      }, props.delay)
    }
  }

  const handleMouseEnter = () => {
    setCursorHover(true)
    setCursorText(props.hovertext)
  }

  const handleMouseLeave = () => {
    setCursorHover(false)
    setCursorText('')
  }

  const renderExternalLink = useCallback(() => {
    return (
      <a
        href={props.to}
        target="_blank"
        rel="noopener"
        title={props.title}
        className={props.className}
        tabIndex={props.tabIndex}
        onClick={props.onClick}
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {props.children}
      </a>
    )
  })

  const renderInternalLink = useCallback(() => {
    return (
      <Link
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        to={cleanOrigin(props.to)}
        {... (props.attribute && { // eslint-disable-line
          'data-text': props.attribute,
        })}
        {...omit(props, [ // eslint-disable-line
          'back',
          'delay',
          'doAnimation',
          'hover',
          'inview',
          'onDelayEnd',
          'onDelayStart',
          'title',
          'to',
          'attribute',
        ])}
      />
    )
  })

  return !isInternal(props.to) || (url.current.length > 1) || props.target === '_blank'
    ? renderExternalLink()
    : renderInternalLink()
}

DelayLink.defaultProps = {
  delay: constants.CONFIG.LINK_DELAY,
  onDelayEnd: () => {},
  onDelayStart: () => {},
  to: '#',
  hovertext: '',
  target: '',
}

export default DelayLink
