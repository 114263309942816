/* eslint-disable prefer-destructuring */
import { memo, useEffect, useRef, useMemo, useCallback, useState } from 'react'
import classNames from 'classnames'
import Splitting from 'splitting'
import { createUseStyles } from 'react-jss'
import { useWindowSize, useRaf } from 'rooks'
import gsap from 'gsap'
import DelayLink from '@/components/DelayLink'
import Modernizr from '@/vendors/modernizr'
import style from './style'

const useStyles = createUseStyles(style)

const NavItem = ({
  value,
  url,
  visible,
  stagger,
  duration,
  className,
}) => {
  const classes = useStyles()
  const $text = useRef()
  const $marquee = useRef()
  const splitting = useRef()
  const { innerWidth } = useWindowSize()
  const marqueeWidth = useRef(0)
  const translateX = useRef(0)

  const [isHover, setHover] = useState(false)

  const marqueeSeparator = useMemo(() => (`<svg width="43" height="34" viewBox="0 0 43 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.4882 0.999999L41.3511 16.8629L25.4882 32.7257" stroke="white" stroke-width="2"/>
<path d="M40.9999 17H-6.14226e-05" stroke="white" stroke-width="2"/>
</svg>`), [])

  /*------------------------------
  Marquee
  ------------------------------*/
  const createMarquee = useCallback(() => {
    $marquee.current.innerHTML += `<span class="marquee-text">${value} ${marqueeSeparator}</span>`
    const width = $marquee.current.clientWidth
    if (width < innerWidth + marqueeWidth.current * 2) {
      createMarquee()
    }
  }, [innerWidth])

  /*------------------------------
  Init
  ------------------------------*/
  useEffect(() => {
    splitting.current = Splitting({
      target: $text.current,
      by: 'chars',
    })[0]
  }, [])

  useEffect(() => {
    $marquee.current.innerHTML = `<span class="marquee-text">${value} ${marqueeSeparator}</span>`
    marqueeWidth.current = $marquee.current.clientWidth
    createMarquee()
  }, [innerWidth])

  /*------------------------------
  Visible
  ------------------------------*/
  useEffect(() => {
    if (splitting.current) {
      splitting.current.chars.forEach((char, ind) => {
        gsap.killTweensOf(char)
        gsap.to(char, {
          opacity: visible ? 1 : 0,
          delay: visible ? 0.5 + ind * stagger : -ind * stagger,
          duration,
        })
      })
    }
  }, [visible])

  /*------------------------------
  Marquee Visible
  ------------------------------*/
  useRaf(() => {
    if (isHover) {
      translateX.current += 1
      if (translateX.current > marqueeWidth.current) {
        translateX.current = 0
      }
      $marquee.current.style.transform = `translateX(${translateX.current}px)`
    }
  }, [isHover])

  return (
    <div
      className={classNames({
        [classes.root]: true,
        [className]: className,
        [classes.hover]: isHover,
      })}
    >
      <div
        onMouseEnter={() => {
          if (!Modernizr.devicehastouch) setHover(true)
        }}
        onMouseLeave={() => {
          if (!Modernizr.devicehastouch) setHover(false)
        }}
      >
        <DelayLink to={url}>
          <span className={classes.text} ref={$text}>{value}</span>
        </DelayLink>
      </div>
      <span className={classes.marquee} ref={$marquee} />
    </div>
  )
}

NavItem.defaultProps = {
  stagger: 0.06,
  duration: 0.8,
}

export default memo(NavItem)
