import { memo, useRef } from 'react'
import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import style from './style'

const useStyles = createUseStyles(style)

const Burger = ({
  height,
  isActive,
  isHover,
  width,
  open,
  close,
  className,
}) => {
  const classes = useStyles({ width, height })
  const $firstLine = useRef()
  const $secondLine = useRef()

  return (
    <div
      className={classNames({
        [classes.root]: true,
        [classes.isActive]: isActive,
        [classes.isHover]: isHover,
        [className]: className,
      })}
    >
      <div className={classes.text}>
        <span className={classes.open}>{open}</span>
        <span className={classes.close}>{close}</span>
      </div>
      <div className={classes.box}>
        <div className={`${classes.inner} inner`}>
          <span ref={$firstLine} />
          <span ref={$secondLine} />
        </div>
      </div>
    </div>
  )
}

Burger.defaultProps = {
  height: 10,
  width: 15,
}

export default memo(Burger)
