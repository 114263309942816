const style = (theme) => {
  const output = {
    root: {
      position: 'fixed',
      top: 0,
      width: '100%',
      height: 'var(--vh)',
      zIndex: theme.zindex.header,
      background: 'transparent',
      pointerEvents: 'none',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      userSelect: 'none',
      '& a, & button': {
        pointerEvents: 'all',
      },
    },
    followImage: {
      position: 'absolute!important',
      zIndex: -1,
      top: 0,
      left: 0,
      width: '25vw',
      height: '25vw',
      transition: 'opacity .1s ease-out',
      transform: 'translate(-50%, -50%)',
      pointerEvents: 'none',
      background: theme.getRgba(theme.colors[0], 0.2),
      opacity: 0,
      '& img': {
        position: 'absolute',
        zIndex: 1,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: 'auto',
        transition: 'opacity 0.2s ease-out',
      },
    },
    followImageVisible: {
      opacity: 1,
    },
    navItem: {
      transition: 'opacity .2s ease-out',
    },
    navItemDisabled: {
      pointerEvents: 'none!important',
      opacity: 0.3,
      '& *': {
        pointerEvents: 'none!important',
      },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    root: {
    },
  }

  return output
}

export default style
