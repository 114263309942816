const style = () => {
  const output = {
    root: {
      opacity: 0,
      '& [data-word]': {
        display: 'inline-block',
      },
    },
  }

  return output
}

export default style
