const style = (theme) => {
  const output = {
    root: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: theme.zindex.loader,
      background: theme.colors[0],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    hide: {
      pointerEvents: 'none',
    },
    logo: {
      width: '100%',
      height: '100%',
    },
    logoContainer: {
      width: 400,
      height: 400,
      overflow: 'hidden',
      '& img': {
        width: 3600,
        height: 1200,
        maxWidth: 'none',
      },
    },
  }
  return output
}

export default style
