const style = (theme) => {
  const output = {
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: 50,
      userSelect: 'none',
      opacity: 1,
    },
    formControl: {
      width: '100%',
      padding: '0 10px',
      marginBottom: 30,
      position: 'relative',
      zIndex: 1,
      '& label': {
        display: 'block',
      },
    },
    formControlDisabled: {
      opacity: 0.6,
      pointerEvents: 'none',
      cursor: 'not-allowed',
    },
    formControlTextarea: {
      width: '100%',
      marginBottom: 30,
    },
    formControlCheckbox: {
      width: '100%',
      marginBottom: 15,
    },
    formControlRadio: {
      width: '100%',
      marginBottom: 15,
    },
    formControlSelect: {
      width: '100%',
      marginBottom: 30,
      zIndex: 2,
    },

    // Generic Input
    input: {
      width: '100%',
      color: theme.colors[1],
      appearance: 'none',
      padding: [16, 28],
      fontSize: 16,
      borderRadius: 6,
      fontWeight: 300,
      fontFamily: theme.fonts[1],
      background: theme.getRgba(theme.colors[5], 0.7),
      border: `1px solid ${theme.colors[5]}`,
      transition: 'border 0.2s ease-in',
      '&::placeholder': {
        color: theme.getRgba(theme.colors[1], 0.3),
      },
      '&:focus': {
        borderColor: theme.colors[1],
      },
    },

    // Textarea
    textarea: {
      width: '100%',
      color: theme.colors[3],
      background: theme.colors[5],
      appearance: 'none',
      padding: [16, 28],
      fontSize: 16,
      fontFamily: theme.fonts[1],
      fontWeight: 300,
      borderRadius: 6,
      resize: 'none',
      border: '1px solid transparent',
      transition: 'border 0.3s ease-in',
      '&::placeholder': {
        color: theme.colors[4],
      },
    },

    // Select
    select: {
      '& .customSelect__control': {
        color: theme.colors[3],
        background: theme.getRgba(theme.colors[4], 0.2),
        fontSize: 16,
        fontFamily: theme.fonts[1],
        borderRadius: 6,
        padding: '8px 28px',
        border: '1px solid transparent',
      },
      '& .customSelect__placeholder': {
        color: theme.getRgba(theme.colors[0], 0.3),
      },
      '& .customSelect__menu': {
        color: theme.colors[2],
        background: theme.colors[7],
        fontSize: 16,
        fontFamily: theme.fonts[0],
        borderRadius: 10,
        border: 'none',
      },
      '& .customSelect__value-container': {
        padding: 0,
      },
      '& .customSelect__indicator-separator': {
        display: 'none',
      },
      '& .customSelect__control--is-focused': {
        borderColor: 'transparent',
        boxShadow: 'none',
      },
      '& .customSelect__option--is-focused': {
        background: '#fae189',
      },
      '& .customSelect__option--is-selected': {
        background: theme.colors[3],
      },
    },

    // Checkbox
    checkbox: {
      position: 'relative',
      zIndex: 1,
      overflow: 'hidden',
      fontSize: 12,
      fontFamily: theme.fonts[1],
      fontWeight: 300,
      color: theme.getRgba(theme.colors[1], 0.3),
      padding: [10, 0],
      '& input[type="checkbox"]': {
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: 0.001,
      },
      '& input[type="checkbox"] + span': {
        display: 'inline-block',
        paddingLeft: 30,
        position: 'relative',
        zIndex: 1,
        lineHeight: '16px',
        '& a': {
          color: theme.colors[1],
        },
        '&:before': {
          content: '""',
          position: 'absolute',
          cursor: 'pointer',
          zIndex: 1,
          top: 0,
          left: 0,
          width: 16,
          height: 16,
          borderRadius: 2,
          border: `1px solid ${theme.getRgba(theme.colors[1], 0.3)}`,
          background: theme.getRgba(theme.colors[1], 0.01),
          transition: 'all .2s ease-in-out',
        },
        '&:after': {
          content: '""',
          position: 'absolute',
          cursor: 'pointer',
          zIndex: 1,
          top: 4,
          left: 3,
          width: 10,
          height: 8,
          backgroundImage: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'11.811\' height=\'8.59\'%3E%3Cpath d=\'M11.541.249a.84.84 0 0 0-1.193 0l-6.261 6.27-2.631-2.64A.86.86 0 1 0 .262 5.115l3.227 3.227a.84.84 0 0 0 1.193 0l6.859-6.856a.84.84 0 0 0 0-1.235Z\' fill=\'%23151515\'/%3E%3C/svg%3E")',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          opacity: 0,
          transition: 'all .2s ease-in-out',
        },
      },
      '& input[type="checkbox"]:checked + span': {
        '&:before': {
          background: theme.colors[1],
        },
        '&:after': {
          opacity: 1,
        },
      },
    },

    // Radio
    radioGroup: {
      borderTop: `1px solid ${theme.getRgba(theme.colors[1], 0.1)}`,
      paddingTop: 15,
    },
    radio: {
      position: 'relative',
      zIndex: 1,
      overflow: 'hidden',
      fontSize: 12,
      fontFamily: theme.fonts[1],
      fontWeight: 300,
      color: theme.getRgba(theme.colors[1], 0.3),
      padding: [10, 0],
      '& input[type="radio"]': {
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: 0.001,
      },
      '& input[type="radio"] + span': {
        display: 'inline-block',
        paddingLeft: 30,
        position: 'relative',
        zIndex: 1,
        lineHeight: '16px',
        '& a': {
          color: theme.colors[1],
        },
        '&:before': {
          content: '""',
          position: 'absolute',
          cursor: 'pointer',
          zIndex: 1,
          top: 0,
          left: 0,
          width: 16,
          height: 16,
          borderRadius: '50%',
          border: `1px solid ${theme.getRgba(theme.colors[1], 0.3)}`,
          background: theme.getRgba(theme.colors[1], 0.01),
          transition: 'all .2s ease-in-out',
        },
        '&:after': {
          content: '""',
          position: 'absolute',
          cursor: 'pointer',
          zIndex: 1,
          top: 4,
          left: 3,
          width: 10,
          height: 8,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          opacity: 0,
          transition: 'all .2s ease-in-out',
        },
      },
      '& input[type="radio"]:checked + span': {
        '&:before': {
          background: theme.colors[1],
          boxShadow: `inset 0 0 0 2px ${theme.colors[2]}`,
        },
        '&:after': {
          opacity: 1,
        },
      },
    },

    // Labels
    label: {
      '& a': {
        display: 'inline-block',
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 0,
          height: 1,
          width: '100%',
          background: 'currentColor',
          transition: 'all .3s ease-out',
          transformOrigin: 'left center',
        },
        '&:hover': {
          '&::before': {
            animation: '$underline .6s forwards',
          },
        },
      },
    },
    '@keyframes underline': {
      '0%': { transform: 'scaleX(0)' },
      '100%': { transform: 'scaleX(1)' },
    },

    // File Field
    formControlUpload: {
      width: '100%',
      marginBottom: 30,
      '& input': {
        width: 0.1,
        height: 0.1,
        opacity: 0,
        overflow: 'hidden',
        position: 'absolute',
        zIndex: -1,
      },
      '& svg': {
        position: 'absolute',
        zIndex: 1,
        top: '50%',
        transform: 'translateY(-50%)',
        right: 40,
        width: 13,
        height: 13,
        fill: theme.colors[5],
      },
      '& label': {
        display: 'block',
        cursor: 'pointer',
        width: '100%',
        color: theme.getRgba(theme.colors[0], 0.3),
        appearance: 'none',
        padding: '16px 28px',
        fontSize: 16,
        fontFamily: theme.fonts[0],
        border: '2px solid transparent',
        background: theme.getRgba(theme.colors[2], 0.05),
        borderRadius: 6,
        transition: 'all .3s ease-out',
        userSelect: 'none',
        '& span': {
          display: 'inline-block',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          verticalAlign: 'top',
          width: '100%',
        },
      },
    },
    formControlIsFill: {
      '& label': {
        color: theme.colors[2],
      },
    },

    // Errors
    formError: {
      '& input': {
        borderColor: '#cc0000',
      },
      '& input[type="checkbox"] + span': {
        '&:before': {
          borderColor: '#cc0000',
        },
      },
      '& .customSelect__control': {
        borderColor: '#cc0000',
      },
      '&$formControlUpload': {
        '& label': {
          borderColor: '#cc0000',
        },
      },
    },

    // Submit Button
    submitButton: {
      position: 'relative',
      zIndex: 1,
      cursor: 'pointer',
      borderRadius: 6,
      fontSize: 14,
      fontWeight: 500,
      fontFamily: theme.fonts[1],
      color: theme.colors[1],
      background: theme.colors[3],
      padding: [16, 28],
      margin: [15, 10, 0, 10],
      width: '100%',
      textAlign: 'center',
      '& span': {
        transition: `opacity 0.5s ${theme.easings['power3.out']}`,
        display: 'inline-block',
        marginRight: 4,
      },
    },
    submitButtonSubmitting: {
      '& span': {
        opacity: 0,
      },
    },
    spinner: {
      position: 'absolute',
    },

    // Result Message
    result: {
      width: '100%',
      textAlign: 'center',
      extend: theme.typography.general,
    },

    // Error Field
    error: {
      position: 'absolute',
      left: 40,
      bottom: -15,
      fontSize: 10,
      fontWeight: 300,
      fontFamily: theme.fonts[1],
      color: '#cc0000',
      opacity: 0,
      transition: 'opacity 0.3s ease-out',
      pointerEvents: 'none',
    },

    visible: {
      opacity: 1,
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    formControl: {
      width: '100%',
    },
  }

  return output
}

export default style
