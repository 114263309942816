import Modernizr from '@/vendors/modernizr'

const global = (theme) => {
  const output = {
    root: {
    },
    wrapper: {
      // mask: 'url(https://raw.githubusercontent.com/supahfunk/supah-codepen/master/video-clip-mask.png)',
      // maskSize: '1800% 100%',
      // maskPosition: '10% 50%',
      // position: 'fixed',
      // top: '0',
      // left: '0',
      // width: '100%',
      // height: 'var(--vh)',
    },
    '@global': {
      body: {
        extend: theme.typography.general,
        color: theme.colors[1],
        background: theme.colors[0],
        overflow: 'hidden',
        '--margin': '100px',
        '--headerHeight': '150px',
        '& a': {
          textDecoration: 'none',
          transition: 'color .3s ease-in-out',
          extend: theme.link,
          color: theme.colors[2],
          '&:before': {
            bottom: 7,
          },
        },
        '& button': {
          fontFamily: theme.fonts[0],
          padding: 0,
          lineHeight: 1,
        },
        '& input': {
          fontFamily: theme.fonts[0],
          padding: 0,
        },
        '& h1, & h2, & h3, & h4': {
          fontWeight: 'normal',
          margin: 0,
          padding: 0,
        },
        '& .smooth-scroll': {
          overflowX: 'hidden',
          overflowY: 'auto',
          height: Modernizr.nativescroll ? 'var(--vh-fixed)' : 'auto',
        },
        '& .scrollbar-block': {
          overflow: 'hidden',
        },
        '& .canvas': {
          zIndex: -1,
          pointerEvents: 'none',
          position: 'fixed!important',
          height: 'var(--vh)',
        },
      },
      '::selection': {
        background: theme.getRgba(theme.colors[10], 0.4),
        color: theme.colors[0],
      },
      '::-moz-selection': {
        background: theme.getRgba(theme.colors[10], 0.4),
        color: theme.colors[0],
      },
    },
  }

  /*------------------------------
  XL
  ------------------------------*/
  output[theme.mediaInverse.xl] = {
    '@global': {
      body: {
        '--headerHeight': '130px',
      },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    '@global': {
      body: {
        '--margin': '60px',
        '--headerHeight': '80px',
      },
    },
  }

  return output
}

export default global
