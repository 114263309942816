import { memo, useEffect, useRef, useCallback, useState } from 'react'
import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import ResizeObserver from 'resize-observer-polyfill'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useContext } from 'use-context-selector'
import useMediaQuery from '@/hooks/useMediaQuery'
import Logo from '@/components/Logo'
import Burger from '@/components/Burger'
import LangSwitcher from '@/components/LangSwitcher'
import DelayLink from '@/components/DelayLink'
import { Context as ScrollbarContext } from '@/context/scrollbar'
import { useRaf, useResize } from '@/components/Handlers'
import * as layerActions from '@/actions/layer'
import * as layoutActions from '@/actions/layout'
import * as boundsActions from '@/actions/bounds'
import * as cursorActions from '@/actions/cursor'
import style from './style'

const useStyles = createUseStyles(style)

const Header = ({ treshold }) => {
  const mediaQueryKey = useMediaQuery()

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { isMenuOpen, strings, languages, layoutStyle, socialMenu, isHeaderMinimize, isHeaderLocked, imageIsZoomed } = useSelector((state) => ({
    isMenuOpen: state.layer.layers.some((layer) => layer.id === 'menu' && layer.isOpen),
    strings: state.options.strings,
    languages: state.locale.languages,
    layoutStyle: state.layout.style,
    isHeaderMinimize: state.layout.header.minimize,
    isHeaderLocked: state.layout.header.locked,
    socialMenu: state.nav.socialMenu || [],
    productMenu: state.nav.productMenu || [],
    imageIsZoomed: state.cursor.imageIsZoomed || false,
  }), shallowEqual)

  const { scroll, direction: directionFromContext } = useContext(ScrollbarContext)
  const classes = useStyles({ layoutStyle })
  const [isBurgerButtonHover, setBurgerButtonHover] = useState(false)
  const [isTop, setTop] = useState(true)
  const [direction, setDirection] = useState(null)
  const $root = useRef()
  const observerRef = useRef()
  const rootBounds = useRef()

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const openMenu = useCallback(() => dispatch(layerActions.openMenu()), [dispatch])
  const closeMenu = useCallback(() => dispatch(layerActions.closeMenu()), [dispatch])
  const setHeaderBounds = useCallback((bounds) => dispatch(boundsActions.setBoundsElement('header', bounds)), [dispatch])
  const setLayoutHeaderMinimize = useCallback((bool) => dispatch(layoutActions.setLayoutHeaderMinimize(bool)), [dispatch])
  const setCursorHover = useCallback((bool) => dispatch(cursorActions.setCursorValue('hover', bool)), [dispatch])

  /*------------------------------
  Handle Mouse Move
  ------------------------------*/
  const handleMouseMove = (e) => {
    if (e.type === 'mouseenter') {
      setBurgerButtonHover(true)
      setCursorHover(true)
    }
    if (e.type === 'mouseleave') {
      setBurgerButtonHover(false)
      setCursorHover(false)
    }
  }

  /*------------------------------
  Calculate Header Bounds
  ------------------------------*/
  const handleResize = useCallback(() => {
    if ($root.current) {
      rootBounds.current = $root.current.getBoundingClientRect()
      setHeaderBounds(rootBounds.current)
    }
  }, [])

  /*------------------------------
  Handle Resize
  ------------------------------*/
  useResize(() => {
    handleResize()
  })

  /*------------------------------
  Resize observer to solve height height issue
  ------------------------------*/
  useEffect(() => {
    if ($root.current) {
      observerRef.current = new ResizeObserver(() => {
        if ($root.current) handleResize()
      })
      observerRef.current.observe($root.current)
    }
    return () => {
      if ($root.current) observerRef.current.disconnect($root.current)
    }
  }, [$root])

  /*------------------------------
  Handle Main Menu Click
  ------------------------------*/
  const handleMainMenuClick = useCallback(() => {
    if (isMenuOpen) closeMenu()
    if (!isMenuOpen) openMenu()
  }, [isMenuOpen])

  /*------------------------------
  Prevent Press Spacebar
  ------------------------------*/
  const preventPressSpacebar = useCallback((node) => {
    if (node !== null) {
      node.addEventListener('keyup', (e) => {
        if (e.keyCode === 32 && e.code === 'Space') e.preventDefault()
      })
    }
  }, [])

  /*------------------------------
  Update
  ------------------------------*/
  const update = useCallback(() => {
    if (scroll.current.y <= treshold) setTop(true)
    if (scroll.current.y > treshold) setTop(false)
    setDirection(directionFromContext.current)
  }, [])

  /*------------------------------
  Set Minimize after change direction
  ------------------------------*/
  useEffect(() => {
    if (!isHeaderLocked) setLayoutHeaderMinimize(direction === 'down')
  }, [direction, isHeaderLocked])

  /*------------------------------
  Set Header Bounds after minimizing transition end
  ------------------------------*/
  useEffect(() => {
    $root.current.addEventListener('transitionend', handleResize)
    return () => $root.current.removeEventListener('transitionend', handleResize)
  }, [])

  /*------------------------------
  Request Animation Frame
  ------------------------------*/
  useRaf(() => {
    update()
  }, [isTop])

  /*------------------------------
  Render Social Nav
  ------------------------------*/
  const renderSocialNav = useCallback(() => {
    return (
      <ul
        className={classNames({
          [classes.socialMenu]: true,
          [classes.hidden]: isMenuOpen || imageIsZoomed,
        })}
      >
        {socialMenu.map((item, i) => (
          <li key={i.toString()}>
            <DelayLink to={item.url}>
              {mediaQueryKey[0] <= 1 ? item.attr : item.title}
            </DelayLink>
          </li>
        ))}
      </ul>
    )
  }, [socialMenu, isMenuOpen, mediaQueryKey, imageIsZoomed])

  /*------------------------------
  Render Languages
  ------------------------------*/
  const renderLanguages = useCallback(() => {
    return languages && Object.keys(languages).length > 1 && (
    <div
      className={classNames({
        [classes.lang]: true,
        [classes.hidden]: imageIsZoomed,
      })}
    >
      <LangSwitcher />
    </div>
    )
  }, [languages, isMenuOpen, imageIsZoomed])

  /*------------------------------
  Render Burger
  ------------------------------*/
  const renderBurgerButton = useCallback(() => {
    return (
      <button
        className={classNames({
          [classes.burgerButton]: true,
          [classes.hidden]: imageIsZoomed,
        })}
        onClick={handleMainMenuClick}
        aria-label="toggle-main-menu"
        ref={preventPressSpacebar}
        onMouseEnter={(e) => handleMouseMove(e)}
        onMouseLeave={(e) => handleMouseMove(e)}
      >
        <Burger
          open={strings.menu_open}
          close={strings.menu_close}
          className={classes.burger}
          isActive={isMenuOpen}
          isHover={isBurgerButtonHover}
        />
      </button>
    )
  }, [strings, isMenuOpen, isBurgerButtonHover, imageIsZoomed])

  return (
    <>
      <header
        className={classNames({
          [classes.root]: true,
          [classes.rootIsMenuOpen]: isMenuOpen,
          [classes.rootIsMinimize]: !isTop && isHeaderMinimize,
          [classes.imageIsZoomed]: imageIsZoomed,
        })}
        ref={$root}
      >
        <Logo
          className={classNames({
            [classes.logo]: true,
          })}
        />
        {renderBurgerButton()}
        {renderSocialNav()}
        {renderLanguages()}
      </header>
    </>
  )
}

Header.defaultProps = {
  treshold: 10,
  menuLabel: 'Menu',
}

export default memo(Header)
