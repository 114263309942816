const style = (theme) => {
  const output = {
    root: {
      textAlign: 'center',
      extend: theme.typography.headings,
      textTransform: 'uppercase',
      display: 'block',
      width: '100%',
      position: 'relative',
      zIndex: 1,
      padding: [10, 0],
      overflow: 'hidden',
      '& .char': {
        display: 'inline-block',
        opacity: 0,
      },
      '& a': {
        color: 'currentColor',
        paddingLeft: 30,
        paddingRight: 30,
        '&:before': {
          display: 'none',
        },
      },
    },
    text: {
      transition: 'opacity .1s ease-out',
    },
    marquee: {
      position: 'absolute',
      zIndex: 1,
      top: 10,
      right: 0,
      color: theme.colors[2],
      verticalAlign: 'middle',
      whiteSpace: 'nowrap',
      opacity: 0,
      transition: 'opacity .1s ease-out',
      '& svg': {
        width: '0.6em',
        height: '0.6em',
      },
      '& .marquee-text': {
        paddingRight: '.3em',
      },
    },
    hover: {
      '& $text': {
        opacity: 0,
      },
      '& $marquee': {
        opacity: 1,
      },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    root: {
      padding: [2, 0],
    },
  }

  return output
}

export default style
