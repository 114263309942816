import { memo, useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector, shallowEqual } from 'react-redux'
import { Canvas } from '@react-three/fiber'
import gsap from 'gsap'
import Loader from '@/components/Loader'
import Header from '@/components/Header'
import Spinner from '@/components/Spinner'
import CursorFollow from '@/components/CursorFollow'
import CursorWebgl from '@/components/CursorWebgl'
import NoiseWebgl from '@/components/NoiseWebgl'
import ModalVideo from '@/components/ModalVideo'
import Nav from '@/components/Nav'
import SVGSprite from '@/components/SVGSprite'
import useFontPreload from '@/hooks/useFontPreload'
import useScrollTriggerProxy from '@/hooks/useScrollTriggerProxy'
import useChangePathname from '@/hooks/useChangePathname'
import global from '@/styles/global'

const useStyles = createUseStyles(global)

const Layout = ({ children }) => {
  const classes = useStyles()
  const $wrapper = useRef()

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { isMenuOpen } = useSelector((state) => ({
    isMenuOpen: state.layer.layers.some((layer) => layer.id === 'menu' && layer.isOpen),
  }), shallowEqual)

  /*------------------------------
  Precache fonts
  ------------------------------*/
  useFontPreload({ fonts: ['LuthonSouthardSerif', 'KarasumaGothic'] })

  /*------------------------------
  Init Scroll Trigger Proxy
  ------------------------------*/
  useScrollTriggerProxy()

  /*------------------------------
  Manage Change Pathname
  ------------------------------*/
  useChangePathname()

  /*------------------------------
  Menu Open
  ------------------------------*/
  useEffect(() => {
    gsap.killTweensOf($wrapper.current)
    gsap.to($wrapper.current, {
      autoAlpha: isMenuOpen ? 0 : 1,
      pointerEvents: isMenuOpen ? 'none' : 'all',
      duration: isMenuOpen ? 1 : 1.2,
      ease: isMenuOpen ? 'power3.out' : 'power3.in',
    })
  }, [isMenuOpen])

  return (
    <div className={`${classes.root} content-root`}>
      <div
        ref={$wrapper}
        className={classes.wrapper}
      >
        {children}
      </div>
      <SVGSprite />
      <Spinner />
      <Nav />
      <Header />
      <ModalVideo />
      <CursorFollow />
      <Canvas className="canvas">
        <CursorWebgl size={20} />
        <NoiseWebgl />
      </Canvas>
      <Loader />
    </div>
  )
}

export default memo(Layout)
