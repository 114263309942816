const style = (theme) => {
  const output = {
    root: {
      top: 0,
      left: 0,
      width: '100%',
      height: 'var(--vh)',
      position: 'fixed',
      zIndex: theme.zindex.modal,
      background: theme.getRgba(theme.colors[0], 0.9),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    close: {
      zIndex: 100,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      position: 'absolute',
      top: 13,
      right: 15,
      width: 50,
      height: 50,
      transition: `all 0.3s ${theme.easings['power4.out']}`,
      background: theme.colors[0],
      borderRadius: '50%',
      '& svg': {
        width: 16,
        height: 16,
        fill: theme.colors[1],
      },
    },
    wrapper: {
      position: 'relative',
      margin: ['auto', 'auto'],
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    iframe: {
      width: '100vw',
      height: '56.25vw',
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    close: {
      top: 15,
      right: 15,
      width: 40,
      height: 40,
      '& svg': {
        width: 12,
        height: 12,
        fill: theme.colors[1],
      },
    },
  }

  return output
}

export default style
