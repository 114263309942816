import hexToRgba from 'hex-rgba'
import generateBreakPoints from 'react-jss-grid/utils/breakpoints'

const padding = [0, '80px']
const paddingMobile = [0, '20px']

export const sizes = {
  xs: 575,
  sm: 576,
  md: 720,
  lg: 992,
  xl: 1025,
  xxl: 1440,
}
const media = {
  xs: `@media (max-width: ${sizes.sm - 1}px)`,
  sm: `@media (min-width: ${sizes.sm}px)`,
  md: `@media (min-width: ${sizes.md}px)`,
  lg: `@media (min-width: ${sizes.lg}px)`,
  xl: `@media (min-width: ${sizes.xl}px)`,
  xxl: `@media (min-width: ${sizes.xxl}px)`,
}
const mediaInverse = {
  xs: `@media (max-width: ${sizes.sm - 1}px)`,
  sm: `@media (max-width: ${sizes.md - 1}px)`,
  md: `@media (max-width: ${sizes.lg - 1}px)`,
  lg: `@media (max-width: ${sizes.xl - 1}px)`,
  xl: `@media (max-width: ${sizes.xxl - 1}px)`,
  xxl: `@media (min-width: ${sizes.xxl}px)`,
  smLand: `@media (max-width: ${sizes.md - 1}px) and (orientation: landscape)`,
}

const colors = [
  '#000000', // #0 Dark
  '#ffffff', // #1 White
  '#D3C300', // #2 Yellow
  '#D30027', // #3 Red
  '#b2b2b2', // #4 // Cancellabili Light Grey
  '#292929', // #5 // Cancellabili Grey
  '#D3D3D3', // #6 // Cancellabili Medium Grey
  '#efefef', // #7 // Cancellabili Dark White
  '#585858', // #8 // Cancellabili Footer Dark Grey
  '#69BC51', // #9 // Cancellabili Green
  '#D3C300', // #10 // Yellow Selection CSS
  '#f06564', // #11 // Cancellabili Red Light
]

const categoriesColors = [
  '#AA9A8B', // Hospitality
  '#90A2B2', // Automotive
  '#83908A', // Adventure
]

const fonts = [
  'KarasumaGothic, Helvetica, Arial, sans-serif', // [0]
  'LuthonSouthardSerif, serif', // [1]
]

const easings = {
  'power3.out': 'cubic-bezier(0.215, 0.610, 0.355, 1.000)', // [0]
  'power3.inOut': 'cubic-bezier(0.645, 0.045, 0.355, 1.000)', // [1]
  'power4.out': 'cubic-bezier(0.76, 0, 0.24, 1)', // [2]
  garret: 'cubic-bezier(0.5, 0, 0, 1);', // [3]
}

const general = {
  fontFamily: fonts[0],
  fontSize: 22,
  lineHeight: 1.8,
  letterSpacing: '0.1em',
  '& p': {
    marginBottom: '2em',
  },
  '& strong, & b': {
    color: colors[2],
  },
  [mediaInverse.lg]: {
    fontSize: 18,
    lineHeight: 1.6,
  },
}

const strokeText = {
  color: 'colorCurrent',
  filter: 'url(#stroke-text)',
}

const small = {
  fontFamily: fonts[0],
  fontSize: 12,
  lineHeight: 1.2,
  letterSpacing: '0.2em',
}

const title = {
  fontSize: 36,
  textTransform: 'uppercase',
  lineHeight: 1.15,
  fontFamily: fonts[1],
  fontWeight: 'normal',
  color: colors[1],
  [mediaInverse.sm]: {
    fontSize: 25,
  },
}

const subtitle = {
  fontFamily: fonts[0],
  fontSize: 18,
  lineHeight: 1.2,
  letterSpacing: '0.1em',
  [mediaInverse.sm]: {
    fontSize: 12,
  },
}

const headings = {
  fontSize: 70,
  lineHeight: 1.15,
  fontFamily: fonts[1],
  fontWeight: 'normal',
  color: colors[1],
  '& strong, & b': {
    color: colors[2],
  },
  '& p': {
    margin: 0,
  },
  [mediaInverse.lg]: {
    fontSize: 50,
    lineHeight: 1.2,
  },
}

const textIndent = {
  fontFamily: fonts[0],
  fontWeight: 'normal',
  margin: 0,
  padding: 0,
  '& strong, & b': {
    fontWeight: 'bold',
  },
  '& p': {
    margin: 0,
  },
}

const pretitle = {
  fontSize: 14,
  textDecoration: 'underline',
  [mediaInverse.sm]: {
    fontSize: 12,
  },
}

const typography = {
  general: {
    extend: general,
  },
  small: {
    extend: small,
  },
  headings: {
    extend: headings,
  },
  title: {
    extend: title,
  },
  strokeText: {
    extend: strokeText,
  },
  subtitle: {
    extend: subtitle,
  },
}

const zindex = {
  overlay: 101,
  mainNav: 102,
  header: 103,
  cursor: 102,
  modal: 103,
  cookiebar: 300,
  spinner: 499,
  loader: 500,
}

const gutter = {
  lg: 6,
  sm: 4,
}

const wrapper = {
  paddingLeft: '18vw',
  paddingRight: '18vw',
  [mediaInverse.xl]: {
    paddingLeft: '8vw',
    paddingRight: '8vw',
  },
  [mediaInverse.sm]: {
    paddingLeft: 24,
    paddingRight: 24,
  },
}

const page = {
  paddingTop: 'var(--headerHeight)',
  overflowX: 'hidden',
}

const frame = {
  paddingLeft: gutter.lg,
  paddingRight: gutter.lg,
  [mediaInverse.sm]: {
    paddingLeft: gutter.sm,
    paddingRight: gutter.sm,
  },
}

const wrapperFrame = {
  paddingLeft: `calc(2.3vw + ${gutter.lg}px)`,
  paddingRight: `calc(2.3vw + ${gutter.lg}px)`,
  [mediaInverse.sm]: {
    paddingLeft: `calc(24px + ${gutter.sm}px)`,
    paddingRight: `calc(24px + ${gutter.sm}px)`,
  },
}

const link = {
  position: 'relative',
  zIndex: 1,
  display: 'inline-block',
  cursor: 'pointer',
  '&:before': {
    content: '""',
    position: 'absolute',
    zIndex: 1,
    left: 0,
    bottom: -1,
    width: '100%',
    height: 1,
    backgroundColor: 'currentColor',
    transition: `transform .8s ${easings['power3.out']}`,
    transform: 'scaleX(0)',
    transformOrigin: '100% 0',
  },
  '&:hover': {
    color: colors[2],
  },
  '@media (hover: hover)': {
    '&:hover': {
      '&:before': {
        transform: 'scaleX(1)',
        transformOrigin: '0 0',
      },
    },
  },
}

const linkInverse = {
  position: 'relative',
  zIndex: 1,
  display: 'inline-block',
  '&:before': {
    content: '""',
    position: 'absolute',
    zIndex: 1,
    left: 0,
    bottom: 1,
    width: '100%',
    height: 1,
    backgroundColor: 'currentColor',
    transition: `transform .6s ${easings['power3.out']}`,
    transform: 'scaleX(0)',
    transformOrigin: '100% 0',
  },
  '@media (hover: hover)': {
    '&:hover': {
      '&:before': {
        transform: 'scaleX(1)',
        transformOrigin: '0 0',
      },
    },
  },
}

export const theme = {
  media,
  mediaInverse,
  colors,
  categoriesColors,
  zindex,
  gutter,
  wrapper,
  wrapperFrame,
  page,
  frame,
  easings,
  fonts,
  pretitle,
  textIndent,
  typography,
  link,
  linkInverse,
  maxWidth: 1600,
  padding,
  paddingMobile,
  header: {
    top: 20,
    bottom: 20,
    logo: {
      width: 153,
      height: 50,
    },
    burger: {
      wrap: 45,
      width: 15,
      height: 10,
    },
    topBar: {
      height: 40,
    },
  },
  animations: {
    ease: 'cubic-bezier(0.650, 0.075, 0.400, 0.935)',
    time: '0.6s',
  },
  getRgba: (col, opacity) => hexToRgba(col, opacity * 100),
  breakpoints: generateBreakPoints({
    values: sizes,
  }),
}
