import { useCallback } from 'react'
import injectSheet from 'react-jss'
import ReactHtmlParser from 'react-html-parser'
import classNames from 'classnames'
import DelayLink from '@/components/DelayLink'
import { cleanOrigin } from '@/utils/path'

import style from '../style'

const RadioField = ({
  classes,
  // label,
  options,
  name,
  onBlur,
  required,
  type,
  error,
  touched,
  submitCount,
  setFieldValue,
  disabled,
}) => {
  const transformContent = useCallback((l) => {
    const transform = (node, i) => { // eslint-disable-line
      if (node.type === 'tag' && node.name === 'a') {
        return (
          <DelayLink
            key={i.toString()}
            to={cleanOrigin(node.attribs.href)}
          >
            {node.children[0].data}
          </DelayLink>
        )
      }
    }
    return ReactHtmlParser(l, { transform })
  }, [])

  return (
    <div
      className={classNames({
        [classes.formControl]: true,
        [classes.formControlDisabled]: disabled,
        [classes.formControlRadio]: true,
        [classes.formError]: (error && touched) || (error && submitCount >= 1),
      })}
    >
      <div
        role="group"
        aria-labelledby={name}
        className={classes.radioGroup}
      >
        {options.map((option) => (
          <label
            key={option.id}
            className={classes.radio}
          >
            <input
              id={name}
              name={name}
              type={type}
              value={option.name}
              onChange={() => {
                setFieldValue(name, option.name)
              }}
              onBlur={onBlur}
            />
            <span className={classes.label}>
              {transformContent(option.label)}
              {' '}
              {required ? '*' : ''}
            </span>
          </label>
        ))}
      </div>
      <div
        className={classNames({
          [classes.error]: true,
          [classes.visible]: (error && touched) || (error && submitCount >= 1),
        })}
      >
        {error}
      </div>
    </div>
  )
}

export default injectSheet(style)(RadioField)
