import { useState, useEffect, useRef } from 'react'
import injectSheet from 'react-jss'
import classNames from 'classnames'
import HTMLParse from 'react-html-parser'
import gsap from 'gsap'
import { Formik } from 'formik'
import { createYupSchema, createInitialValues } from '@/utils/form'
import TextField from '@/components/WpContactForm7/Fields/TextField'
import TextareaField from '@/components/WpContactForm7/Fields/TextareaField'
import SelectField from '@/components/WpContactForm7/Fields/SelectField'
import FileField from '@/components/WpContactForm7/Fields/FileField'
import CheckboxField from '@/components/WpContactForm7/Fields/CheckboxField'
import RadioField from '@/components/WpContactForm7/Fields/RadioField'

import style from './style'

const WpContactForm7 = ({
  onSuccessSend,
  classes,
  className,
  fields,
  formID,
  sendContactForm,
  submitLabel,
  hiddenValues,
  handleCallback,
}) => {
  const [state, setState] = useState(null || '')
  const $form = useRef()
  const $result = useRef()
  const formSchema = useRef({})
  const initialValues = useRef({})
  const [isMultipart, setMultipart] = useState(false)

  useEffect(() => {
    formSchema.current = createYupSchema(fields)
    initialValues.current = createInitialValues(fields)

    // Check if is Multipart
    setMultipart(fields.some((field) => field.type === 'file'))
  }, [])

  useEffect(() => {
    if (state !== '') {
      gsap.to($form.current, {
        opacity: 1,
        onComplete: () => {
          gsap.set($form.current, {
            display: 'none',
          })
          gsap.set($result.current, {
            display: 'block',
          })
          gsap.to($result.current, {
            opacity: 1,
          })
        },
      })
    } else {
      gsap.to($result.current, {
        opacity: 0,
        onComplete: () => {
          gsap.set($result.current, {
            display: 'none',
          })
          gsap.set($form.current, {
            display: 'flex',
          })
          gsap.to($form.current, {
            opacity: 1,
          })
        },
      })
    }
    handleCallback(state)
  }, [state])

  return fields.length > 0 && (
    <>
      <Formik
        initialValues={initialValues.current}
        validationSchema={formSchema.current}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const data = { ...values, _wpcf7_recaptcha_response: '', ...hiddenValues }
          const result = sendContactForm(formID, data, isMultipart)

          result
            .then((response) => {
              if (response.data.status === 'mail_sent') {
                onSuccessSend()
              }
              setState(response.data.message)
              setSubmitting(false)
            })
            .then(() => {
              setTimeout(() => {
                resetForm()
                setState('')
              }, 20000)
            })
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, submitCount, setFieldValue }) => {
          return (
            <form
              className={classNames({
                [classes.root]: true,
                [className]: className,
              })}
              ref={$form}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              {fields.map((field, i) => {
                if (field.type === 'text' || field.type === 'tel' || field.type === 'email' || field.type === 'url' || field.type === 'number' || field.type === 'date') {
                  return (
                    <TextField
                      key={i.toString()}
                      type={field.type}
                      label={field.label}
                      name={field.name}
                      required={field.required}
                      placeholder={`${field.placeholder || field.label} ${field.required ? '*' : ''}`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[field.name]}
                      error={errors[field.name]}
                      touched={touched[field.name]}
                      submitCount={submitCount}
                    />
                  )
                }
                if (field.type === 'textarea') {
                  return (
                    <TextareaField
                      key={i.toString()}
                      label={field.label}
                      name={field.name}
                      required={field.required}
                      placeholder={`${field.placeholder || field.label} ${field.required ? '*' : ''}`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[field.name]}
                      error={errors[field.name]}
                      touched={touched[field.name]}
                      submitCount={submitCount}
                    />
                  )
                }
                if (field.type === 'select') {
                  return (
                    <SelectField
                      key={i.toString()}
                      label={field.label}
                      name={field.name}
                      required={field.required}
                      options={field.options}
                      includeBlank={field.include_blank}
                      onBlur={handleBlur}
                      value={values[field.name]}
                      error={errors[field.name]}
                      touched={touched[field.name]}
                      submitCount={submitCount}
                      setFieldValue={setFieldValue}
                    />
                  )
                }
                if (field.type === 'file') {
                  return (
                    <FileField
                      key={i.toString()}
                      type={field.type}
                      label={field.label}
                      name={field.name}
                      required={field.required}
                      onBlur={handleBlur}
                      value={values[field.name]}
                      error={errors[field.name]}
                      touched={touched[field.name]}
                      submitCount={submitCount}
                      setFieldValue={setFieldValue}
                    />
                  )
                }
                if (field.type === 'checkbox') {
                  return (
                    <CheckboxField
                      key={i.toString()}
                      type={field.type}
                      label={field.label}
                      name={field.name}
                      required={field.required}
                      onBlur={handleBlur}
                      value={values[field.name]}
                      error={errors[field.name]}
                      touched={touched[field.name]}
                      submitCount={submitCount}
                      setFieldValue={setFieldValue}
                    />
                  )
                }
                if (field.type === 'radio') {
                  return (
                    <RadioField
                      key={i.toString()}
                      type={field.type}
                      label={field.label}
                      name={field.name}
                      options={field.options}
                      required={field.required}
                      onBlur={handleBlur}
                      value={values[field.name]}
                      error={errors[field.name]}
                      touched={touched[field.name]}
                      submitCount={submitCount}
                      setFieldValue={setFieldValue}
                      disabled={isSubmitting}
                    />
                  )
                }
                return null
              })}
              <button
                className={classNames({
                  [classes.submit]: true,
                  TextField__submit: true,
                })}
                type="submit"
                disabled={isSubmitting}
                aria-label="submit-form"
              >
                <span>{submitLabel}</span>
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 11L6 6L1 1" stroke="black" strokeWidth="2" strokeLinecap="round" />
                </svg>
              </button>
            </form>
          )
        }}
      </Formik>
      <div
        ref={$result}
        className={classNames({
          [classes.result]: true,
        })}
      >
        {HTMLParse(state.replace('.', '.<br />'))}
      </div>
    </>
  )
}

WpContactForm7.defaultProps = {
  submitLabel: 'submit',
  onSuccessSend: () => {},
  hiddenValues: {},
  fields: [],
  handleCallback: () => { },
}

export default injectSheet(style)(WpContactForm7)
