/* eslint-disable no-unused-vars */
import { useRef, useEffect } from 'react'
import Splitting from 'splitting'
import classNames from 'classnames'
import gsap from 'gsap'
import { createUseStyles } from 'react-jss'
import style from './style'

const useStyles = createUseStyles(style)

const RevealChars = ({ className, children, stagger, inview, enterDelay, animation, autoReset, duration, ease }) => {
  const classes = useStyles()
  const $root = useRef()
  const splitting = useRef()

  const reset = () => {
    if (!autoReset || !$root.current) return
    const output = $root.current.innerHTML
      .replace(/<span class="whitespace">(\s)<\/span>/g, '$1')
      .replace(/<span class="char" [^>]+>(\w+)<\/span>/g, '$1')
      .replace(/<span class="word" [^>]+>(\w+)<\/span>/g, '$1')

    setTimeout(() => {
      if ($root.current) {
        $root.current.innerHTML = output
      }
    }, 1000)
  }

  useEffect(() => {
    // eslint-disable-next-line prefer-destructuring
    splitting.current = Splitting({
      target: $root.current,
      by: 'chars',
    })[0]
    $root.current.style.opacity = 1

    if (animation === 'scale') {
      gsap.set(splitting.current.chars, {
        scale: 0,
        transformOrigin: '50% 100%',
        opacity: 0,
        display: 'inline-block',
      })
    }

    if (animation === 'fade') {
      gsap.set(splitting.current.chars, {
        opacity: 0,
      })
    }
  }, [])

  useEffect(() => {
    splitting.current.chars.forEach((t, index) => {
      gsap.killTweensOf(t)

      if (animation === 'scale') {
        gsap.to(t, {
          scale: inview ? 1 : 0,
          opacity: inview ? 1 : 0,
          ease,
          delay: enterDelay + index * stagger,
          duration,
          onComplete: () => {
            if (index === splitting.current.chars.length - 1) {
              reset()
            }
          },
        })
      }

      if (animation === 'fade') {
        gsap.to(t, {
          opacity: inview ? 1 : 0,
          delay: enterDelay + index * stagger,
          ease,
          duration,
          onComplete: () => {
            if (index === splitting.current.chars.length - 1) {
              reset()
            }
          },
        })
      }
    })
  }, [inview])

  return (
    <div
      ref={$root}
      className={classNames({
        [classes.root]: true,
        [className]: className,
      })}
    >
      {children}
    </div>
  )
}

RevealChars.defaultProps = {
  stagger: 0.05,
  inview: false,
  enterDelay: 0,
  duration: 1.8,
  autoReset: false,
  ease: 'power3.inOut',
  animation: 'scale', // scale || fade
}

export default RevealChars
