import { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react'
import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import YouTube from 'react-youtube'
import VideoTimeline from '@/components/YoutubeVideoTimeline'
import usePrevious from '@/hooks/usePrevious'
import style from './style'

const useStyles = createUseStyles(style)

const YoutubeVideo = forwardRef(({
  videoId,
  poster,
  className,
  autoplay,
}, ref) => {
  const classes = useStyles(style)
  const [isPlaying, setPlaying] = useState(false)
  const [isFirst, setFirst] = useState(true)
  const [isLoading, setLoading] = useState(false)
  const $root = useRef(null)
  const $video = useRef(null)
  const videoPlayer = useRef(null)
  const videoIframe = useRef(null)
  const [posterYoutube, setPosterYoutube] = useState()
  const opts = useRef({
    playerVars: {
      autoplay: 0,
      controls: 0,
      showinfo: 0,
      color: 'white',
      rel: 0,
      fs: 1,
      modestbranding: 1,
      playsinline: 0,
    },
    host: 'https://www.youtube.com',
  })

  // Check youtube poster
  function fetchYoutubePoster(id, index = 0) {
    const youtubePosterRes = ['maxresdefault', 'hqdefault', 'mqdefault', 'default']
    const image = youtubePosterRes[index]
    fetch(`//img.youtube.com/vi/${id}/${image}.jpg`).then((res) => {
      if (res.ok) {
        setPosterYoutube(`https://img.youtube.com/vi/${id}/${image}.jpg`)
      } else if (index < youtubePosterRes.length) {
        fetchYoutubePoster(id, index + 1)
      }
    })
  }

  useEffect(() => {
    if (!poster) {
      fetchYoutubePoster(videoId)
    }
  }, [])

  const onClickPlay = () => {
    if (videoPlayer.current) {
      if (isFirst) {
        videoPlayer.current.playVideo()
        videoPlayer.current.mute()
        setLoading(true)
      } else {
        videoPlayer.current.playVideo()
        videoPlayer.current.unMute()
        setPlaying(true)
      }
    }
  }

  const goFullscreen = () => {
    videoIframe.current = $root.current.querySelector('iframe')
    const requestFullScreen = videoIframe.current.requestFullScreen || videoIframe.current.mozRequestFullScreen || videoIframe.current.webkitRequestFullscreen || videoIframe.current.msRequestFullscreen
    if (requestFullScreen) requestFullScreen.bind(videoIframe.current)()
  }

  useImperativeHandle(ref, () => ({
    play: onClickPlay,
  }))

  const prevIsFirst = usePrevious(isFirst)
  useEffect(() => {
    if (prevIsFirst && !isFirst) {
      onClickPlay()
      setLoading(false)
    }
  }, [isFirst])

  const onReady = (event) => {
    videoPlayer.current = event.target
    if (autoplay) onClickPlay()
  }

  const onStateChange = (e) => {
    if (e.target.getPlayerState() === 1 && isFirst) {
      setTimeout(() => videoPlayer.current.pauseVideo(), 100)
      setTimeout(() => videoPlayer.current.seekTo(0), 200)
      setTimeout(() => setFirst(false), 3000)
    }
  }

  const onClickPause = () => {
    if (videoPlayer.current) setPlaying(false)
  }

  const onEnd = () => {
    videoPlayer.current.seekTo(0)
    onClickPause()
  }

  const prevIsPlaying = usePrevious(isPlaying)
  useEffect(() => {
    if (prevIsPlaying && !isPlaying) setTimeout(() => videoPlayer.current.pauseVideo(), 300)
  }, [isPlaying])

  return (
    <div
      ref={$root}
      className={`${classes.root} ${className} youtubeVideo`}
    >
      <YouTube
        ref={$video}
        videoId={videoId}
        opts={opts.current}
        onReady={onReady}
        onStateChange={onStateChange}
        onEnd={onEnd}
      />
      <div
        className={classNames({
          [classes.poster]: true,
          [classes.posterHide]: isPlaying,
          youtubeVideo__poster: true,
        })}
        style={{ backgroundImage: `url(${poster || posterYoutube})` }}
      >
        <button
          className={classNames({
            [classes.play]: true,
            [classes.playHide]: isLoading,
            youtubeVideo__play: true,
          })}
          type="button"
          onClick={onClickPlay}
        >
          <svg viewBox="0 0 31.5 36.37">
            <title>Play</title>
            <path d="M30.5 18.19l-15 8.66-15 8.66V.87l15 8.66 15 8.66z" />
          </svg>
        </button>
        <div
          className={classNames({
            [classes.loading]: true,
            [classes.loadingShow]: isLoading,
            youtubeVideo__loading: true,
          })}
        >
          <svg className="spinner" viewBox="0 0 50 50">
            <circle className="path" cx="25" cy="25" r="20" fill="none" />
          </svg>
        </div>
      </div>
      {videoPlayer.current && (
        <div className={`${classes.timeline} youtubeVideo__timeline`}>
          <VideoTimeline
            video={videoPlayer.current}
            isPlaying={isPlaying}
            onClickPause={onClickPause}
            goFullscreen={goFullscreen}
          />
        </div>
      )}
    </div>
  )
})

YoutubeVideo.defaultProps = {
  autoplay: false,
}

export default YoutubeVideo
