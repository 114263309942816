import { useEffect, useState, useRef } from 'react'
import { theme } from '@/styles/style'

const useMediaQuery = () => {
  const media = useRef([])
  const mediaInverse = useRef([])
  const mediaRange = useRef([])
  const [query, setQuery] = useState('')
  const [queryKey, setQueryKey] = useState(0)

  useEffect(() => {
    Object.entries(theme.media).map((item) => {
      const value = item[1]
      media.current.push(value.replace('@media ', ''))
      return false
    })
    Object.entries(theme.mediaInverse).map((item) => {
      const value = item[1]
      mediaInverse.current.push(value.replace('@media ', ''))
      return false
    })

    Object.entries(theme.media).map((item, i) => {
      const key = item[0]
      mediaRange.current[key] = `${media.current[i]} and ${mediaInverse.current[i]}`
      const setMedia = () => {
        if (media.current[key].matches) {
          setQuery(key)
          setQueryKey(i)
        }
      }
      media.current[key] = window.matchMedia(mediaRange.current[key])
      media.current[key].addListener(() => {
        setMedia()
      })
      setMedia()
      return false
    })
  }, [])

  return [queryKey, query]
}

export default useMediaQuery
